// import React, { useState, useEffect } from "react";
// import { styled } from "@mui/material/styles";
// import {
//   Autocomplete,
//   Paper,
//   Grid,
//   Box,
//   TextField,
//   Button,
//   Checkbox,
//   Typography,
//   InputLabel,
//   MenuItem,
//   Select,
//   Chip,
// } from "@mui/material";
// import { FormControl } from "@material-ui/core";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import AxiosInstance from "../../../apis/AxiosInstance";
// import { FaRegTrashAlt, FaPlus } from "react-icons/fa";
// import moment from "moment/moment";
// import Modal from "@mui/material/Modal";
// import Cookies from "js-cookie";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   boxShadow: "none",
//   color: theme.palette.text.secondary,
// }));

// const NewCreateAddressingForm = () => {
//   const [send_message, SetSend_message] = useState(false);
//   const [send_email, setSend_email] = useState(false);
//   const [is_broadcaster, setIsBroadCaster] = useState(false);
//   const [assign_quiz, setAssign_quiz] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   // state values
//   const [addressing_date, setAddressing_date] = useState("");
//   const [addressing_time, setAddressing_time] = useState("");
//   // for filtering
//   const [addressing_dateF, setAddressing_dateF] = useState("");

//   const [addressing_timeF, setAddressing_timeF] = useState("");

//   const [rounds, setRounds] = useState([{ id: 1, label: "Select Round 1" }]);
//   // const [checked, setChecked] = useState(false);
//   // const [checked1, setChecked1] = useState(false);
//   const [valuesAdd, setValuesAdd] = useState([""]);

//   const navigate = useNavigate();

//   const [selectedColgOptions, setSelectedColgOptions] = useState([]);
//   const [selectedColgOptions_1, setSelectedColgOptions_1] = useState([]);
//   const [selectedColgOptions_2, setSelectedColgOptions_2] = useState([]);

//   // State values for API calls
//   const [appRoundsOptions, setAppRoundsOptions] = useState([]);
//   const [addFacultyOptions, setAddFacultyOptions] = useState([]);
//   const [addressing_faculty, setAddressing_faculty] = useState([]);
//   const [collegeExecutiveOptions, setCollegeExecutiveOptions] = useState([]);
//   const [college_Executive, setCollege_Executive] = useState([]);
//   const [colgOptions, setColgOptions] = useState([]);
//   //new
//   const [colgOptions_1, setColgOptions_1] = useState([]);
//   const [colgOptions_2, setColgOptions_2] = useState([]);

//   const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");
//   const [searchCollegeExecutive, setSearchCollegeExecutive] = useState("");

//   //Exam type list

//   const [examTypeListOption, setExamTypeListOption] = useState([]);
//   const [examTypeList, setExamTypeList] = useState("");

//   const [videoData, setVideoData] = useState(null);
//   const [videoOption, setVideoOption] = useState([]);
//   const [videoSearch, setVideoSearch] = useState("");
//   const [videoError, setVideoError] = useState(false);

//   // ==============================================add rounds==============================================
//   const addRound = () => {
//     setValuesAdd([...valuesAdd, ""]);
//   };

//   const removeRound = (idToRemove) => {
//     let list = [...valuesAdd];
//     list.splice(idToRemove, 1);
//     setValuesAdd(list);
//   };

//   const handleValueChange = (id, newValue) => {
//     setValuesAdd({ ...valuesAdd, [id]: newValue });
//     let newval = valuesAdd.map((item, ind) => {
//       return ind === newValue ? id : item;
//     });
//     setValuesAdd(newval);
//   };
//   // ==============================================add rounds==============================================

//   //!===========================================FUNCTION TO HANDLE FORM SUBMITION=========================

//   const handleCloseModal = () => {
//     setOpenModal(false);
//   };

//   let handleSubmit = async (ex) => {
//     // event.preventDefault();
//     let formData = new FormData();
//     // Tokens and config
//     const parseLog = (a) => {
//       const userData = a ? JSON.parse(a) : { token: "" };
//       const { token } = userData;
//       return token;
//     };
//     const token = parseLog(Cookies.get("logged"));

//     let config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `${token}`,
//       },
//     };
//     let addFac = [];
//     addressing_faculty.map((value) => {
//       formData.append("addressing_faculty_id", value.id);
//     });
//     college_Executive.map((value) => {
//       formData.append("college_executive_id", value.id);
//     });
//     try {
//       if (
//         (selectedColgOptions === null || selectedColgOptions.length === 0) &&
//         (selectedColgOptions_1 === null || selectedColgOptions_1.length === 0)
//       ) {
//         toast.error("Please select an appointment");
//         return; // Stop further execution
//       }
//       if (selectedColgOptions !== null) {
//         selectedColgOptions.forEach((e, i) => {
//           formData.append("appointment_id", e.id);
//         });
//       }
//       if (selectedColgOptions_1 !== null) {
//         selectedColgOptions_1.forEach((e, i) => {
//           formData.append("appointment_id", e.id);
//         });
//       }

//       if (is_broadcaster && (!videoData || !videoData.id)) {
//         setVideoError(true); // Set error state for video selection
//         toast.error("Video is required when broadcaster is selected");
//         return;
//       } else {
//         setVideoError(false); // Clear error state if video is selected
//       }

//       if (videoData && videoData.id) {
//         formData.append("video_id", videoData.id);
//       }
//       const time = new Date(addressing_date + "T" + addressing_time);
//       const addressDateTime = moment(time)
//         .utc()
//         .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");
//       formData.append("date_time", addressDateTime);
//       // formData.append("addressing_faculty_id", addFac);
//       formData.append("send_email", send_email);
//       // formData.append("assign_quiz",assign_quiz)
//       formData.append("send_message", send_message);
//       formData.append("is_broadcaster", is_broadcaster);
//       formData.append("is_offline", examTypeList === "1" ? true : false);
//       valuesAdd.forEach((e, i) => {
//         formData.append(`round${i + 1}_id`, Number.parseInt(e));
//       });
//       if (
//         (examTypeList === "1" &&
//           college_Executive &&
//           college_Executive.length > 0) ||
//         (examTypeList === "2" &&
//           addressing_faculty &&
//           addressing_faculty.length > 0)
//       ) {
//         await AxiosInstance.post("/addressing", formData, config)
//           .then((response) => {
//             toast.success("Successfully Addressing is submitted");
//             setTimeout(() => {
//               if (ex === "x") {
//                 navigate("/assign-quiz?to=2", { state: response.data.data });
//               } else if (ex === "y") {
//                 navigate("/");
//               }
//             }, 1600);
//           })
//           .catch((error) => {
//             toast.error(error.response.data.error);
//           });
//       } else {
//         if (examTypeList === "") {
//           toast.error("please select mode of addressing");
//         } else if (examTypeList === "1") {
//           toast.error("please select a college executive");
//         } else {
//           toast.error("please select a addressing faculty");
//         }
//       }
//     } catch (error) {
//       toast.error(error);
//     }
//     //   if((examTypeList==='1'&&college_Executive && college_Executive.length>0 &&addressing_faculty && addressing_faculty.length>0) ||(examTypeList==='2'&&addressing_faculty && addressing_faculty.length>0 &&college_Executive && college_Executive.length>0 ) ){
//     //     await AxiosInstance.post("/addressing", formData, config)
//     //     .then((response) => {
//     //       toast.success("Successfully Addressing is submitted");
//     //       setTimeout(() => {
//     //         if (ex === "x") {
//     //           navigate("/assign-quiz?to=2", { state: response.data.data });
//     //         } else if (ex === "y") {
//     //           navigate("/");
//     //         }
//     //         // navigate("/");
//     //         // navigate("/quizTree");
//     //       }, 1600);
//     //     })
//     //     .catch((error) => {
//     //       toast.error(error.response.data.error);
//     //     });
//     //   }else{
//     //     if(examTypeList===""){
//     //       toast.error('please select mode of addressing')
//     //     }else if(examTypeList==='1'){
//     //       toast.error('please select a college executive and addressing faculty ')
//     //     }else{
//     //       toast.error('please select a addressing faculty and college executive')
//     //     }
//     //   }
//     // } catch (error) {
//     //   toast.error(error);
//     // }
//   };

//   //! ================================================API CALLS=============================================================

//   // Exam type
//   useEffect(() => {
//     let fetchData = async () => {
//       let examTypeData = await AxiosInstance.get(`/exam_types`);
//       let finalExamTypeData = examTypeData.data.data;
//       setExamTypeListOption(finalExamTypeData);
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     let fetchData = async (e) => {
//       if (videoSearch !== "") {
//         let data = await AxiosInstance.get(
//           `/videos?video_type_id=1&search_keyword=${videoSearch}&limit=10`
//         );
//         let finalData = data.data.data;
//         setVideoOption(finalData);
//       } else {
//         let data = await AxiosInstance.get(`/videos?video_type_id=1&limit=10`);
//         let finalData = data.data.data;
//         setVideoOption(finalData);
//       }
//     };

//     fetchData();
//   }, [videoSearch]);

//   useEffect(() => {
//     let fetchData = async (e) => {
//       // College appointment rounds api
//       let appointmentRounds = await AxiosInstance.get(
//         `/college_appointment_rounds`
//       );
//       let finalColgRoundsData = appointmentRounds.data.data;
//       setAppRoundsOptions(finalColgRoundsData);
//     };
//     fetchData();
//   }, []);

//   //get addressing faculty

//   useEffect(() => {
//     let fetchData = async () => {
//       // Addressing Faculty api
//       if (searchAddressingFaculty !== "") {
//         let addFacultyData = await AxiosInstance.get(
//           `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
//         );
//         let finalAddFaculty = addFacultyData.data.data;
//         if (finalAddFaculty) {
//           setAddFacultyOptions(finalAddFaculty);
//         }
//       } else {
//         let addFacultyData = await AxiosInstance.get(
//           `/user_mini?role_ids=11&is_not_blocked=${true}`
//         );
//         let finalAddFaculty = addFacultyData.data.data;
//         setAddFacultyOptions(finalAddFaculty);
//       }
//     };
//     fetchData();
//   }, [searchAddressingFaculty]);

//   //get Executives

//   useEffect(() => {
//     let fetchData = async () => {
//       // College Executive api
//       if (searchCollegeExecutive !== "") {
//         let collegeExecutive = await AxiosInstance.get(
//           `/user_mini?role_ids=14&search_keyword=${searchCollegeExecutive}&is_not_blocked=${true}`
//         );
//         let collegeExecutiveData = collegeExecutive.data.data;
//         if (collegeExecutiveData) {
//           setCollegeExecutiveOptions(collegeExecutiveData);
//         }
//       } else {
//         let collegeExecutive = await AxiosInstance.get(
//           `/user_mini?role_ids=14&is_not_blocked=${true}`
//         );
//         let collegeExecutiveData = collegeExecutive.data.data;
//         setCollegeExecutiveOptions(collegeExecutiveData);
//       }
//     };
//     fetchData();
//   }, [searchCollegeExecutive]);

//   ///Selecting Appointment Based on Appointment Date
//   const [inputValue, setInputValue] = useState([]);
//   const [defaultCourseList1, setDefaultCourseList1] = useState([]);

//   useEffect(() => {
//     if (addressing_dateF) {
//       fetchData1();
//     } else {
//       setColgOptions_1([]);
//       setDefaultCourseList1([]);
//     }
//   }, [addressing_dateF]);

//   const fetchData1 = async () => {
//     try {
//       let response = await AxiosInstance.get(
//         `/appointment_mini?appointment_date=${addressing_dateF}&limit=10`
//       );
//       let finalData = response.data.data;
//       if (Array.isArray(finalData)) {
//         setColgOptions_1(finalData);
//         setDefaultCourseList1(finalData);
//       } else {
//         setColgOptions_1([]);
//         setDefaultCourseList1([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setColgOptions_1([]);
//       setDefaultCourseList1([]);
//     }
//   };

//   const fetchOptions = async (searchTerm_1) => {
//     try {
//       const response = await AxiosInstance.get(
//         `/appointment_mini?appointment_date=${addressing_dateF}&search_keyword=${searchTerm_1}&limit=10`
//       );
//       const data = response.data.data;
//       if (Array.isArray(data)) {
//         setColgOptions_1(data);
//       } else {
//         setColgOptions_1([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setColgOptions_1([]);
//     }
//   };

//   const handleAddDateF = (e) => {
//     const newDate = e.target.value;
//     setAddressing_dateF(newDate);
//   };

//   const handleInputChange = (event, newInputValue) => {
//     setInputValue(newInputValue);
//     if (newInputValue.length >= 3) {
//       fetchOptions(newInputValue);
//     } else {
//       setColgOptions_1(defaultCourseList1);
//     }
//   };

//   const handleOptionSelect = (_, newValue, action) => {
//     setSelectedColgOptions_1(newValue);
//   };

//   ///Selecting Appointment Based on Appointment Time
//   const [inputValue2, setInputValue2] = useState([]);
//   const [defaultCourseList2, setDefaultCourseList2] = useState([]);

//   // let fetchData2 = async () => {
//   //   let response = await AxiosInstance.get(
//   //     `/appointment_mini?appointment_time=${addressing_timeF}&limit=10`
//   //   );
//   //   let finalData = response.data.data;
//   //   setColgOptions_2(finalData);
//   //   setDefaultCourseList2(finalData);
//   // };
//   // const fetchOptions2 = async (searchTerm_1) => {
//   //   try {
//   //     const response = await AxiosInstance.get(
//   //       `/appointment_mini?appointment_time=${addressing_timeF}&search_keyword=${searchTerm_1}&limit=10`
//   //     );
//   //     const data = response.data.data;
//   //     if (Array.isArray(data)) {
//   //       setColgOptions_2(data);
//   //     } else {
//   //       setColgOptions_2([]);
//   //     }
//   //   } catch (error) {
//   //     console.error("Error fetching data:", error);
//   //   }
//   // };

//   // const handleInputChange2 = (event, newInputValue) => {
//   //   setInputValue2(newInputValue);
//   //   if (newInputValue.length >= 3) {
//   //     fetchOptions2(newInputValue);
//   //   } else {
//   //     setColgOptions_2(defaultCourseList2);
//   //   }
//   // };

//   // const handleOptionSelect2 = (_, newValue, action) => {
//   //   setSelectedColgOptions_1(newValue);
//   // };

//   useEffect(() => {
//     if (addressing_dateF) {
//       fetchData1();
//     } else {
//       setColgOptions_1([]);
//       setDefaultCourseList1([]);
//     }
//   }, [addressing_dateF]);

//   const fetchData2 = async () => {
//     try {
//       let response = await AxiosInstance.get(
//         `/appointment_mini?appointment_time=${addressing_timeF}&limit=10`
//       );
//       let finalData = response.data.data;
//       if (Array.isArray(finalData)) {
//         setColgOptions_2(finalData);
//         setDefaultCourseList2(finalData);
//       } else {
//         setColgOptions_2([]);
//         setDefaultCourseList2([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setColgOptions_2([]);
//       setDefaultCourseList2([]);
//     }
//   };

//   const fetchOptions2 = async (searchTerm_1) => {
//     try {
//       const response = await AxiosInstance.get(
//         `/appointment_mini?appointment_time=${addressing_timeF}&search_keyword=${searchTerm_1}&limit=10`
//       );
//       const data = response.data.data;
//       if (Array.isArray(data)) {
//         setColgOptions_2(data);
//       } else {
//         setColgOptions_2([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setColgOptions_2([]);
//     }
//   };

//   const handleAddTimeF = (e) => {
//     const newDate = e.target.value;
//     setAddressing_timeF(newDate);
//   };

//   const handleInputChange2 = (event, newInputValue) => {
//     setInputValue2(newInputValue);
//     if (newInputValue.length >= 3) {
//       fetchOptions2(newInputValue);
//     } else {
//       setColgOptions_2(defaultCourseList2);
//     }
//   };

//   const handleOptionSelect2 = (_, newValue, action) => {
//     setSelectedColgOptions_1(newValue);
//   };

//   //Selecting All Appointments
//   const [inputValue1, setInputValue1] = useState([]);
//   const [defaultCourseList, setDefaultCourseList] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let response = await AxiosInstance.get(`/appointment_mini?limit=10`);
//         let finalData = response.data.data;
//         if (Array.isArray(finalData)) {
//           setColgOptions(finalData);
//           setDefaultCourseList(finalData);
//         } else {
//           console.error("Data is not an array:", finalData);
//           setColgOptions([]);
//           setDefaultCourseList([]);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setColgOptions([]);
//         setDefaultCourseList([]);
//       }
//     };
//     fetchData();
//   }, []);
//   const fetchOptions1 = async (searchTerm_1) => {
//     try {
//       const response = await AxiosInstance.get(
//         `/appointment_mini?search_keyword=${searchTerm_1}&limit=10`
//       );
//       const data = response.data.data;
//       if (Array.isArray(data)) {
//         setColgOptions(data);
//       } else {
//         setColgOptions([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleInputChange1 = (event, newInputValue) => {
//     setInputValue1(newInputValue);
//     if (newInputValue.length >= 3) {
//       fetchOptions1(newInputValue);
//     } else {
//       setColgOptions_1(defaultCourseList);
//     }
//   };

//   const handleOptionSelect1 = (_, newValue, action) => {
//     setSelectedColgOptions(newValue);
//   };

//   //! ================================================API CALLS=============================================================

//   // to handle Date and Time
//   let handleAddDate = (e) => {
//     setAddressing_date(e.target.value);
//   };
//   let handleAddTime = (e) => {
//     setAddressing_time(e.target.value);
//   };

//   // let handleAddTimeF = (e) => {
//   //   setAddressing_timeF(e.target.value);
//   //   if (e.target.value) {
//   //     fetchData2();
//   //   }
//   // };

//   // Reset Data
//   let resetData = (e) => {
//     navigate("/create_collegeappointment");
//   };

//   const handleSendEmailChange1 = (event) => {
//     setSend_email(event.target.checked);
//   };
//   const handleSendBroadCast = (event) => {
//     setIsBroadCaster(event.target.checked);
//   };
//   const handleSendMsgChange1 = (event) => {
//     SetSend_message(event.target.checked);
//   };
//   const handleAssignQuizChange1 = (event) => {
//     setAssign_quiz(event.target.checked);
//     // if (event.target.checked === true) {
//     //   setOpenModal(true);
//     // }
//   };

//   const checkSubmit = () => {
//     if (assign_quiz) {
//       handleSubmit("x");
//     } else {
//       setOpenModal(true);
//     }
//   };
//   return (
//     <div>
//       <section style={{ width: "100%", height: "auto" }}>
//         <article
//           style={{
//             width: "98%",
//             height: "auto",
//             margin: "0 auto",
//             marginTop: "4.5%",
//           }}
//         >
//           <Box>
//             <form
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 checkSubmit();
//               }}
//             >
//               <Paper variant="outlined" style={{border:"1px solid gray"}}>
//                 <Typography                
//                   sx={{
//                     marginBottom: 2,
//                     background: "#DDE6ED",
//                     color: "#081f37",
//                     margin: "0 auto",
//                     width: "100%",
//                     textAlign: "center",
//                     fontWeight: "bold",
//                     letterSpacing: "1px",
//                     borderBottom: "2px solid #9BA4B5",
//                     fontSize:"25px"
//                   }}
//                   // className={Styles.backgrounds}
//                 >
//                   CREATE ADDRESSING FORM
//                 </Typography>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <Paper sx={{ padding: 1 }} >
//                       {/* forms */}
//                       <section>
//                         {/* parent box for date and time */}
//                         <Box sx={{ display: "flex" }}>
//                           {/* dateee */}
//                           <Box
//                             component={"section"}
//                             sx={{
//                               boxShadow:
//                                 " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
//                               padding: "2px",
//                               marginBottom: "20px",
//                               outline: "1px solid #D6E4E5",
//                               width: "50%",
//                               marginRight: "1%",
//                               borderLeft: "6px solid #9BA4B5",
//                               borderRadius: "10px",
//                             }}
//                           >
//                             <Typography                            
//                               sx={{
//                                 // marginBottom: 2,
//                                 fontWeight: "bold",
//                                 color: "#00337C",
//                               }}
//                               xs={12}
//                             >
//                               Select Existing Appointment Date
//                             </Typography>

//                             <Grid container spacing={1}>
//                               <Grid item md={4} xs={12}>
//                                 <Item>
//                                   {/* <TextField
//                                     type="date"
//                                     fullWidth
//                                     id="addressingDate"
//                                     label="Appointment Date"
//                                     name="addressing_date"
//                                     autoFocus
//                                     value={addressing_dateF}
//                                     onChange={handleAddDateF}
//                                   /> */}
//                                   <TextField
//                                     type="datetime-local"
//                                     fullWidth
//                                     id="addressingDate"
//                                     label="Appointment Date"
//                                     name="addressing_date"
//                                     onFocus={(e) => (e.target.type = "date")}
//                                     onBlur={(e) => (e.target.type = "text")}
//                                     autoFocus
//                                     value={addressing_dateF}
//                                     onChange={handleAddDateF}
//                                     sx={{
//                                       "& .MuiOutlinedInput-root": {
//                                         borderColor: "rgba(0, 0, 0, 10)",
//                                         "& fieldset": {
//                                           borderColor: "rgba(0, 0, 0, 5)",
//                                         },
//                                       },
//                                     }}
//                                   />
//                                 </Item>
//                               </Grid>
//                               <Grid item md={8} xs={12}>
//                                 <Item>
//                                   <Autocomplete
//                                     isOptionEqualToValue={(option, value) =>
//                                       option.id === value.id
//                                     }
//                                     multiple
//                                     required
//                                     value={selectedColgOptions_1}
//                                     onChange={handleOptionSelect}
//                                     onInputChange={handleInputChange}
//                                     options={colgOptions_1}
//                                     getOptionLabel={(option) => option.name}
//                                     noOptionsText={
//                                       colgOptions_1.length === 0
//                                         ? "No options"
//                                         : ""
//                                     }
//                                     renderTags={(tagValue, getTagProps) =>
//                                       tagValue.map((option, index) => (
//                                         <Chip
//                                           key={option.name + (index + 1)}
//                                           label={option.name}
//                                           {...getTagProps({ index })}
//                                         />
//                                       ))
//                                     }
//                                     renderInput={(params) => (
//                                       <TextField
//                                         {...params}
//                                         label="Select Appointments Based On Appointment Date"
//                                         variant="outlined"
//                                         InputProps={{
//                                           ...params.InputProps,
//                                         }}
//                                         sx={{
//                                           "& .MuiOutlinedInput-root": {
//                                             borderColor: "rgba(0, 0, 0, 10)",
//                                             "& fieldset": {
//                                               borderColor: "rgba(0, 0, 0, 5)",
//                                             },
//                                           },
//                                         }}
//                                       />
//                                     )}
//                                   />
//                                 </Item>
//                               </Grid>
//                             </Grid>
//                           </Box>

//                           <Box
//                             component={"section"}
//                             sx={{
//                               boxShadow:
//                                 " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
//                               padding: "2px",
//                               marginBottom: "20px",
//                               outline: "1px solid #D6E4E5",
//                               // marginTop: "1%",
//                               width: "50%",
//                               borderLeft: "6px solid #9BA4B5",
//                               borderRadius: "10px",
//                             }}
//                           >
//                             <Typography
//                               sx={{
//                                 // marginBottom: 2,
//                                 fontWeight: "bold",
//                                 color: "#00337C",
//                               }}
//                               xs={12}
//                             >
//                               Select Existing Appointment Time
//                             </Typography>

//                             <Grid container spacing={1}>
//                               {/* addressing time */}
//                               <Grid item md={4} xs={12}>
//                                 <Item>
//                                   <TextField
//                                     type="datetime-local"
//                                     fullWidth
//                                     id="addressingTime"
//                                     label="Appointment Time"
//                                     name="addressing_date"
//                                     onFocus={(e) => (e.target.type = "time")}
//                                     onBlur={(e) => (e.target.type = "text")}
//                                     autoFocus
//                                     value={addressing_timeF}
//                                     onChange={handleAddTimeF}
//                                     sx={{
//                                       "& .MuiOutlinedInput-root": {
//                                         borderColor: "rgba(0, 0, 0, 10)",
//                                         "& fieldset": {
//                                           borderColor: "rgba(0, 0, 0, 5)",
//                                         },
//                                       },
//                                     }}
//                                   />
//                                 </Item>
//                               </Grid>

//                               {/* //!NEWLY ADDED START time */}
//                               <Grid item md={8} xs={12}>
//                                 {/* <Item onChange={searchAppointments_2}>
//                                   <Autocomplete
//                                     multiple
//                                     options={colgOptions_2 ? colgOptions_2 : []}
//                                     disableCloseOnSelect
//                                     getOptionLabel={(option) => option.name}
//                                     renderOption={(props, option) => (
//                                       <li {...props} style={{ width: "250%" }}>
//                                         {option.name}
//                                       </li>
//                                     )}
//                                     renderInput={(params) => (
//                                       <TextField
//                                         {...params}
//                                         variant="outlined"
//                                         label="Select Multiple Appointments Based On Appointment Time"
//                                         placeholder="Select Multiple Appointments Based On Appointment Time"
//                                       />
//                                     )}
//                                     value={selectedColgOptions_2}
//                                     onChange={(e, value) => {
//                                       setSelectedColgOptions_2(value);
//                                     }}
//                                   />
//                                 </Item> */}
//                                 <Item>
//                                   <Autocomplete
//                                     isOptionEqualToValue={(option, value) =>
//                                       option.id === value.id
//                                     }
//                                     multiple
//                                     required
//                                     value={selectedColgOptions_2}
//                                     onChange={handleOptionSelect2}
//                                     onInputChange={handleInputChange2}
//                                     options={colgOptions_2}
//                                     getOptionLabel={(option) => option.name}
//                                     noOptionsText={
//                                       colgOptions_2.length === 0
//                                         ? "No options"
//                                         : ""
//                                     }
//                                     renderTags={(tagValue, getTagProps) =>
//                                       tagValue.map((option, index) => (
//                                         <Chip
//                                           key={option.name + (index + 1)}
//                                           label={option.name}
//                                           {...getTagProps({ index })}
//                                         />
//                                       ))
//                                     }
//                                     renderInput={(params) => (
//                                       <TextField
//                                         {...params}
//                                         label="Select Appointments Based On Appointment Time"
//                                         variant="outlined"
//                                         InputProps={{
//                                           ...params.InputProps,
//                                         }}
//                                         sx={{
//                                           "& .MuiOutlinedInput-root": {
//                                             borderColor: "rgba(0, 0, 0, 10)",
//                                             "& fieldset": {
//                                               borderColor: "rgba(0, 0, 0, 5)",
//                                             },
//                                           },
//                                         }}
//                                       />
//                                     )}
//                                   />
//                                 </Item>
//                               </Grid>
//                               {/* //!NEWLY ADDED END Time */}
//                             </Grid>
//                           </Box>
//                         </Box>

//                         <Box
//                           component={"section"}
//                           sx={{
//                             boxShadow:
//                               " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
//                             padding: "2px",
//                             marginBottom: "20px",
//                             outline: "1px solid #D6E4E5",
//                             // marginTop: "1%",
//                             borderLeft: "6px solid #9BA4B5",
//                             borderRadius: "10px",
//                             width: "100%",
//                           }}
//                         >
//                           <Typography
//                             // variant="h5"
//                             sx={{
//                               // marginBottom: 2,
//                               fontWeight: "bold",
//                               color: "#00337C",
//                             }}
//                             xs={12}
//                           >
//                             All Appointments Block
//                           </Typography>

//                           {/* new code for all appointments */}
//                           <Grid
//                             container
//                             spacing={2}
//                             item
//                             // sx={{ marginBottom: "30px" }}
//                           >
//                             <Grid item md={12} xs={12}>
//                               <Item>
//                                 <Autocomplete
//                                   isOptionEqualToValue={(option, value) =>
//                                     option.id === value.id
//                                   }
//                                   multiple
//                                   required
//                                   value={selectedColgOptions}
//                                   onChange={handleOptionSelect1}
//                                   onInputChange={handleInputChange1}
//                                   options={colgOptions}
//                                   getOptionLabel={(option) => option.name}
//                                   renderTags={(tagValue, getTagProps) =>
//                                     tagValue.map((option, index) => (
//                                       <Chip
//                                         key={option.name + (index + 1)}
//                                         label={option.name}
//                                         {...getTagProps({ index })}
//                                       />
//                                     ))
//                                   }
//                                   renderInput={(params) => (
//                                     <TextField
//                                       {...params}
//                                       label="Select Multiple Appointments"
//                                       variant="outlined"
//                                       InputProps={{
//                                         ...params.InputProps,
//                                       }}
//                                       sx={{
//                                         "& .MuiOutlinedInput-root": {
//                                           borderColor: "rgba(0, 0, 0, 10)",
//                                           "& fieldset": {
//                                             borderColor: "rgba(0, 0, 0, 5)",
//                                           },
//                                         },
//                                       }}
//                                     />
//                                   )}
//                                 />
//                               </Item>
//                             </Grid>
//                           </Grid>
//                         </Box>

//                         <Grid container spacing={1} sx={{ marginTop: "0.5%" }}>
//                           <Grid item md={1.8} xs={12}>
//                             <TextField
//                               required
//                               type="datetime-local"
//                               fullWidth
//                               id="addressingDate"
//                               label=" Addressing Date"
//                               name="addressing_date"
//                               onFocus={(e) => (e.target.type = "date")}
//                               onBlur={(e) => (e.target.type = "text")}
//                               autoFocus
//                               value={addressing_date}
//                               onChange={handleAddDate}
//                               inputProps={{
//                                 min: new Date().toISOString().split("T")[0],
//                               }}
//                               sx={{
//                                 "& .MuiOutlinedInput-root": {
//                                   borderColor: "rgba(0, 0, 0, 10)",
//                                   "& fieldset": {
//                                     borderColor: "rgba(0, 0, 0, 5)",
//                                   },
//                                 },
//                               }}
//                             />
//                           </Grid>
//                           <Grid item md={1.8} xs={12}>
//                             <TextField
//                               type="datetime-local"
//                               fullWidth
//                               required
//                               id="addressingTime"
//                               label="Addressing Time"
//                               name="addressing_date"
//                               onFocus={(e) => (e.target.type = "time")}
//                               onBlur={(e) => (e.target.type = "text")}
//                               autoFocus
//                               value={addressing_time}
//                               onChange={handleAddTime}
//                               sx={{
//                                 "& .MuiOutlinedInput-root": {
//                                   borderColor: "rgba(0, 0, 0, 10)",
//                                   "& fieldset": {
//                                     borderColor: "rgba(0, 0, 0, 5)",
//                                   },
//                                 },
//                               }}
//                             />
//                           </Grid>

//                           <Grid
//                             item
//                             md={2}
//                             xs={12}
//                             style={{ marginTop: "-1.3%" }}
//                           >
//                             <FormControl fullWidth>
//                               <InputLabel id="demo-simple-select-label">
//                                 Mode Of Addressing
//                               </InputLabel>
//                               <Select
//                                 labelId="demo-simple-select-label"
//                                 id="demo-simple-select"
//                                 required
//                                 displayEmpty
//                                 className="my-select"
//                                 inputProps={{
//                                   "aria-label": "Select an option",
//                                 }}
//                                 value={examTypeList}
//                                 // name="examTypeList"
//                                 onChange={(e) => {
//                                   setExamTypeList(e.target.value);
//                                 }}
//                                 sx={{
//                                   "& .MuiOutlinedInput-notchedOutline": {
//                                     borderColor: "rgba(0, 0, 0, 10)", 
//                                   },
//                                   "&:hover .MuiOutlinedInput-notchedOutline": {
//                                     borderColor: "rgba(0, 0, 0, 10)", 
//                                   },
//                                   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                                     borderColor: "rgba(0, 0, 0, 5)", 
//                                   },
//                                 }}
//                               >
//                                 <MenuItem value="" disabled>
//                                   <span className="my-select-placeholder">
//                                     Select Mode
//                                   </span>
//                                 </MenuItem>
//                                 {Object.entries(examTypeListOption).map(
//                                   ([key, value]) => (
//                                     <MenuItem key={key} value={key}>
//                                       {value}
//                                     </MenuItem>
//                                   )
//                                 )}
//                               </Select>
//                             </FormControl>
//                           </Grid>

//                           <Grid
//                             item
//                             md={3.2}
//                             onChange={(e) => {
//                               setSearchCollegeExecutive(e.target.value);
//                             }}
//                           >
//                             <Autocomplete
//                               multiple
//                               options={
//                                 collegeExecutiveOptions
//                                   ? collegeExecutiveOptions
//                                   : []
//                               }
//                               disableCloseOnSelect
//                               getOptionLabel={(option) => option.full_name}
//                               renderOption={(props, option) => (
//                                 <li {...props}>{option.full_name}</li>
//                               )}
//                               renderInput={(params) => (
//                                 <TextField
//                                   {...params}
//                                   variant="outlined"
//                                   label="Select Multiple College Executive"
//                                   placeholder="Select Multiple College Executive"
//                                   required={college_Executive.length === 0}
//                                   sx={{
//                                     "& .MuiOutlinedInput-root": {
//                                       borderColor: "rgba(0, 0, 0, 10)",
//                                       "& fieldset": {
//                                         borderColor: "rgba(0, 0, 0, 5)",
//                                       },
//                                     },
//                                   }}
//                                 />
//                               )}
//                               value={college_Executive}
//                               onChange={(e, value) => {
//                                 setCollege_Executive(value);
//                               }}
//                               disabled={examTypeList == 2}
//                             />
//                           </Grid>
//                           <Grid
//                             item
//                             md={3.2}
//                             onChange={(e) => {
//                               setSearchAddressingFaculty(e.target.value);
//                             }}
//                           >
//                             <Autocomplete
//                               multiple
//                               options={
//                                 addFacultyOptions ? addFacultyOptions : []
//                               }
//                               disableCloseOnSelect
//                               getOptionLabel={(option) => option.full_name}
//                               renderOption={(props, option) => (
//                                 <li {...props}>{option.full_name}</li>
//                               )}
//                               renderInput={(params) => (
//                                 <TextField
//                                   {...params}
//                                   variant="outlined"
//                                   label="Select Multiple Addressing Faculty"
//                                   placeholder="Select Multiple Addressing Faculty"
//                                   required={addressing_faculty.length === 0}
//                                   sx={{
//                                     "& .MuiOutlinedInput-root": {
//                                       borderColor: "rgba(0, 0, 0, 10)",
//                                       "& fieldset": {
//                                         borderColor: "rgba(0, 0, 0, 5)",
//                                       },
//                                     },
//                                   }}
//                                 />
//                               )}
//                               value={addressing_faculty}
//                               onChange={(e, value) => {
//                                 setAddressing_faculty(value);
//                               }}
//                               disabled={examTypeList == 1}
//                             />
//                           </Grid>
//                           {/* <Grid
//                           item
//                           md={3}
//                           onChange={(e) => {
//                             setVideoSearch(e.target.value);
//                           }}
//                         >
//                           <Autocomplete
//                             options={videoOption}
//                             value={videoData}
//                             name="videoOption"
//                             required
//                             onChange={(event, newData_xy) => {
//                               setVideoData(newData_xy);
//                               if (newData_xy) {
//                                 setVideoError(false); 
//                               }
//                             }}
//                             getOptionLabel={(option) => option.title}
//                             disabled={!is_broadcaster}
//                             renderInput={(params) => (
//                               <TextField
//                                 {...params}
//                                 label="Search Video"
//                                 variant="outlined"
//                                 error={videoError} 
//                                 helperText={
//                                   videoError
//                                     ? "Video is required when broadcaster is selected"
//                                     : ""
//                                 }
//                               />
//                             )}
//                           />
//                         </Grid> */}
//                           <Grid item md={12} xs={12}>
//                             <h3>Interview Rounds</h3>
//                           </Grid>

//                           <Grid container spacing={1} item>
//                             {valuesAdd.map((item, ind) => (
//                               <Grid
//                                 item
//                                 md={2.75}
//                                 style={{
//                                   display: "inline-flex",
//                                   position: "relative",
//                                   // marginRight: "10px",
//                                 }}
//                               >
//                                 <FormControl
//                                   style={{ width: "100%" }}
//                                   key={ind + 1}
//                                 >
//                                   <InputLabel
//                                     id={`demo-simple-select-label-${ind}`} // Set a unique ID for each label
//                                     sx={{
//                                       textAlign: "left",
//                                       fontWeight: "600",
//                                       color: "#FC7300",
//                                     }}
//                                   >
//                                     {`Round ${ind + 1}`}{" "}
//                                     {/* Update the label text here */}
//                                   </InputLabel>

//                                   <Select
//                                     labelId={`demo-simple-select-label-${ind}`} // Reference the label ID
//                                     id="demo-simple-select"
//                                     value={item}
//                                     onChange={(event) =>
//                                       handleValueChange(event.target.value, ind)
//                                     }
//                                     displayEmpty
//                                     className="my-select"
//                                     inputProps={{
//                                       "aria-label": "Select an option",
//                                     }}
//                                     required
//                                     sx={{
//                                       "& .MuiOutlinedInput-notchedOutline": {
//                                         borderColor: "rgba(0, 0, 0, 10)", 
//                                       },
//                                       "&:hover .MuiOutlinedInput-notchedOutline": {
//                                         borderColor: "rgba(0, 0, 0, 10)", 
//                                       },
//                                       "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                                         borderColor: "rgba(0, 0, 0, 5)", 
//                                       },
//                                     }}
//                                   >
//                                     <MenuItem value="" disabled>
//                                       <span className="my-select-placeholder">
//                                         Select Rounds
//                                       </span>
//                                     </MenuItem>
//                                     {appRoundsOptions.map((roundsData, i) => (
//                                       <MenuItem
//                                         key={roundsData.ID}
//                                         value={roundsData.ID}
//                                       >
//                                         {roundsData.name}
//                                       </MenuItem>
//                                     ))}
//                                   </Select>

//                                   {valuesAdd.length !== 1 && (
//                                     <Button
//                                       style={{
//                                         position: "absolute",
//                                         bottom: "-22px",
//                                         right: "40%",
//                                       }}
//                                       onClick={() => removeRound(ind)}
//                                     >
//                                       <FaRegTrashAlt style={{ color: "red" }} />
//                                     </Button>
//                                   )}
//                                   {valuesAdd.length - 1 === ind && (
//                                     <Grid
//                                       item
//                                       md={1}
//                                       style={{
//                                         display:
//                                           valuesAdd.length > 4
//                                             ? "none"
//                                             : "inline-flex",
//                                         position: "absolute",
//                                         right: "-4px",
//                                         top: "25%",
//                                       }}
//                                     >
//                                       <Button onClick={addRound}>
//                                         <FaPlus />
//                                       </Button>
//                                     </Grid>
//                                   )}
//                                 </FormControl>
//                               </Grid>
//                             ))}
//                           </Grid>

//                           {/* trialll */}

//                           <Grid
//                             item
//                             md={12}
//                             style={{ marginTop: "0.5%" }}
//                             xs={12}
//                           >
//                             <Grid container spacing={2} xs={12}>
//                               <Grid item xs={12} md={1.5}>
//                                 <Checkbox
//                                   name="send_message"
//                                   checked={send_message}
//                                   onChange={handleSendMsgChange1}
//                                   id="send_msg"
//                                   sx={{
//                                     "& .MuiSvgIcon-root": { fontSize: 25 },
//                                   }}
//                                 />
//                                 <label
//                                   htmlFor="sendMsg"
//                                   style={{ fontSize: "16px" }}
//                                 >
//                                   Send Message
//                                 </label>
//                               </Grid>
//                               <Grid item xs={12} md={1.5}>
//                                 <Checkbox
//                                   name="send_email"
//                                   checked={send_email}
//                                   onChange={handleSendEmailChange1}
//                                   id="send_mail"
//                                   sx={{
//                                     "& .MuiSvgIcon-root": { fontSize: 25 },
//                                   }}
//                                 />{" "}
//                                 <label
//                                   htmlFor="sendMail"
//                                   style={{ fontSize: "16px" }}
//                                 >
//                                   Send Mail
//                                 </label>
//                               </Grid>
//                               <Grid item xs={12} md={1.5}>
//                                 <Checkbox
//                                   name="is_broadcaster"
//                                   checked={is_broadcaster}
//                                   onChange={handleSendBroadCast}
//                                   id="is_broadcaster"
//                                   sx={{
//                                     "& .MuiSvgIcon-root": { fontSize: 25 },
//                                   }}
//                                   // disabled={examTypeList == 1}
//                                 />{" "}
//                                 <label
//                                   htmlFor="broadcaster"
//                                   style={{ fontSize: "16px" }}
//                                 >
//                                   Video BroadCasting
//                                 </label>
//                               </Grid>
//                               <Grid
//                                 item
//                                 md={3}
//                                 onChange={(e) => {
//                                   setVideoSearch(e.target.value);
//                                 }}
//                               >
//                                 <Autocomplete
//                                   options={videoOption}
//                                   value={videoData}
//                                   name="videoOption"                                  
//                                   onChange={(event, newData_xy) => {
//                                     setVideoData(newData_xy);
//                                     if (newData_xy) {
//                                       setVideoError(false);
//                                     }
//                                   }}
//                                   getOptionLabel={(option) => option.title}
//                                   disabled={!is_broadcaster}
//                                   renderInput={(params) => (
//                                     <TextField
//                                       {...params}
//                                       label="Search Video"
//                                       variant="outlined"
//                                       error={videoError}
//                                       required={is_broadcaster}
//                                       helperText={
//                                         videoError
//                                           ? "Video is required when broadcaster is selected"
//                                           : ""
//                                       }
//                                       sx={{
//                                         "& .MuiOutlinedInput-root": {
//                                           borderColor: "rgba(0, 0, 0, 10)",
//                                           "& fieldset": {
//                                             borderColor: "rgba(0, 0, 0, 5)",
//                                           },
//                                         },
//                                       }}
//                                     />
//                                   )}
//                                 />
//                               </Grid>
//                             </Grid>
//                           </Grid>
//                           <Grid item md={12} xs={12} style={{marginTop:"-0.5%"}}>
//                             <Grid item>
//                               <Checkbox
//                                 name="assign_quiz"
//                                 checked={assign_quiz}
//                                 // checked={openModal}
//                                 onChange={handleAssignQuizChange1}
//                                 id="assign_quiz"
//                                 sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
//                               />{" "}
//                               <label
//                                 htmlFor="assignQuiz"
//                                 style={{ fontSize: "20px", fontWeight: "bold" }}
//                               >
//                                 Assign Quiz
//                               </label>
//                             </Grid>
//                             <Modal
//                               open={openModal}
//                               onClose={handleCloseModal}
//                               BackdropProps={{
//                                 style: {
//                                   backgroundColor: "rgba(0, 0, 0, 0.5)",
//                                 },
//                               }}
//                             >
//                               <div
//                                 style={{
//                                   position: "absolute",
//                                   top: "50%",
//                                   left: "50%",
//                                   transform: "translate(-50%, -50%)",
//                                   width: "30%",
//                                   minHeight: "20vh",
//                                   maxHeight: "90%", // Set your desired max height here
//                                   backgroundColor: "white",
//                                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//                                   borderRadius: "5px",
//                                   padding: 10,
//                                   display: "flex",
//                                   flexDirection: "column",
//                                   alignItems: "center",
//                                   justifyContent: "space-around",
//                                   overflowY: "auto", // Add overflowY for scrolling if content exceeds the max height
//                                 }}
//                               >
//                                 <h3 style={{ textAlign: "center" }}>
//                                   Do you want to continue without assigning the
//                                   quiz?
//                                 </h3>
//                                 <div>
//                                   <button
//                                     style={{
//                                       background: "crimson",
//                                       color: "#fff",
//                                       fontWeight: "bold",
//                                       padding: "8px 20px",
//                                       border: "none",
//                                       borderRadius: "5px",
//                                     }}
//                                     onClick={() => setOpenModal(false)}
//                                   >
//                                     Cancel
//                                   </button>
//                                   &nbsp;&nbsp;
//                                   <button
//                                     style={{
//                                       background: "green",
//                                       color: "#fff",
//                                       fontWeight: "bold",
//                                       border: "none",
//                                       padding: "8px 20px",
//                                       borderRadius: "5px",
//                                     }}
//                                     onClick={() => handleSubmit("y")}
//                                   >
//                                     Proceed
//                                   </button>
//                                 </div>
//                               </div>
//                             </Modal>
//                           </Grid>
//                           <Grid
//                             xs={12}
//                             item
//                             spacing={1}
//                             // sx={{ mt: 5, mb: 2 }}
//                             style={{
//                               textAlign: "right",
//                               // padding: "2px",
//                             }}
//                           >
//                             <Button
//                               onClick={resetData}
//                               type="button"
//                               variant="contained"
//                               style={{
//                                 width: "10%",
//                                 marginRight: "3%",
//                                 backgroundColor: "#E74646",
//                                 color: "white",
//                                 fontWeight: "bold",
//                               }}
//                             >
//                               Cancel
//                             </Button>
//                             <Button
//                               type="submit"
//                               variant="contained"
//                               style={{
//                                 width: "10%",
//                                 backgroundColor: "#1976D2",
//                                 fontWeight: "bold",
//                               }}
//                             >
//                               Save
//                             </Button>
//                           </Grid>
//                         </Grid>
//                       </section>
//                     </Paper>
//                   </Grid>
//                 </Grid>
//               </Paper>
//             </form>
//           </Box>
//         </article>
//       </section>
//     </div>
//   );
// };

// export default NewCreateAddressingForm;


import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Tooltip,
} from "@mui/material";
import { FormControl } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../../apis/AxiosInstance";
import { FaRegTrashAlt, FaPlus } from "react-icons/fa";
import moment from "moment/moment";
import Modal from "@mui/material/Modal";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const NewCreateAddressingForm = () => {
  const [send_message, SetSend_message] = useState(false);
  const [send_email, setSend_email] = useState(false);
  const [is_broadcaster, setIsBroadCaster] = useState(false);
  const [assign_quiz, setAssign_quiz] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // state values
  const [addressing_date, setAddressing_date] = useState("");
  const [addressing_time, setAddressing_time] = useState("");
  // for filtering
  const [addressing_dateF, setAddressing_dateF] = useState("");

  const [addressing_timeF, setAddressing_timeF] = useState("");

  const [rounds, setRounds] = useState([{ id: 1, label: "Select Round 1" }]);
  // const [checked, setChecked] = useState(false);
  // const [checked1, setChecked1] = useState(false);
  const [valuesAdd, setValuesAdd] = useState([""]);

  const navigate = useNavigate();

  const [selectedColgOptions, setSelectedColgOptions] = useState([]);
  const [selectedColgOptions_1, setSelectedColgOptions_1] = useState([]);
  const [selectedColgOptions_2, setSelectedColgOptions_2] = useState([]);

  // State values for API calls
  const [appRoundsOptions, setAppRoundsOptions] = useState([]);
  const [addFacultyOptions, setAddFacultyOptions] = useState([]);
  const [addressing_faculty, setAddressing_faculty] = useState([]);
  const [collegeExecutiveOptions, setCollegeExecutiveOptions] = useState([]);
  const [college_Executive, setCollege_Executive] = useState([]);
  const [colgOptions, setColgOptions] = useState([]);

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  //new
  const [colgOptions_1, setColgOptions_1] = useState([]);
  const [colgOptions_2, setColgOptions_2] = useState([]);

  const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");
  const [searchCollegeExecutive, setSearchCollegeExecutive] = useState("");

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState("");

  const [videoData, setVideoData] = useState(null);
  const [videoOption, setVideoOption] = useState([]);
  const [videoSearch, setVideoSearch] = useState("");
  const [videoError, setVideoError] = useState(false);

  // ==============================================add rounds==============================================
  const addRound = () => {
    setValuesAdd([...valuesAdd, ""]);
  };

  const removeRound = (idToRemove) => {
    let list = [...valuesAdd];
    list.splice(idToRemove, 1);
    setValuesAdd(list);
  };

  const handleValueChange = (id, newValue) => {
    setValuesAdd({ ...valuesAdd, [id]: newValue });
    let newval = valuesAdd.map((item, ind) => {
      return ind === newValue ? id : item;
    });
    setValuesAdd(newval);
  };
  // ==============================================add rounds==============================================

  //!===========================================FUNCTION TO HANDLE FORM SUBMITION=========================

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  let handleSubmit = async (ex) => {
    // event.preventDefault();
    let formData = new FormData();
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    let addFac = [];
    addressing_faculty.map((value) => {
      formData.append("addressing_faculty_id", value.id);
    });
    college_Executive.map((value) => {
      formData.append("college_executive_id", value.id);
    });
    try {
      if (
        (selectedColgOptions === null || selectedColgOptions.length === 0) &&
        (selectedColgOptions_1 === null || selectedColgOptions_1.length === 0)
      ) {
        toast.error("Please select an appointment");
        return; // Stop further execution
      }
      if (selectedColgOptions !== null) {
        selectedColgOptions.forEach((e, i) => {
          formData.append("appointment_id", e.id);
        });
      }
      if (selectedColgOptions_1 !== null) {
        selectedColgOptions_1.forEach((e, i) => {
          formData.append("appointment_id", e.id);
        });
      }

      if (is_broadcaster && (!videoData || !videoData.id)) {
        setVideoError(true); // Set error state for video selection
        toast.error("Video is required when broadcaster is selected");
        return;
      } else {
        setVideoError(false); // Clear error state if video is selected
      }

      if (videoData && videoData.id) {
        formData.append("video_id", videoData.id);
      }
      const time = new Date(addressing_date + "T" + addressing_time);
      const addressDateTime = moment(time)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");
      formData.append("date_time", addressDateTime);
      // formData.append("addressing_faculty_id", addFac);
      formData.append("send_email", send_email);
      // formData.append("assign_quiz",assign_quiz)
      formData.append("send_message", send_message);
      formData.append("is_broadcaster", is_broadcaster);
      formData.append("is_offline", examTypeList === "1" ? true : false);
      valuesAdd.forEach((e, i) => {
        formData.append(`round${i + 1}_id`, Number.parseInt(e));
      });
      if (
        (examTypeList === "1" &&
          college_Executive &&
          college_Executive.length > 0) ||
        (examTypeList === "2" &&
          addressing_faculty &&
          addressing_faculty.length > 0)
      ) {
        await AxiosInstance.post("/addressing", formData, config)
          .then((response) => {
            toast.success("Successfully Addressing is submitted");
            setTimeout(() => {
              if (ex === "x") {
                navigate("/assign-quiz?to=2", { state: response.data.data });
              } else if (ex === "y") {
                navigate("/");
              }
            }, 1600);
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } else {
        if (examTypeList === "") {
          toast.error("please select mode of addressing");
        } else if (examTypeList === "1") {
          toast.error("please select a college executive");
        } else {
          toast.error("please select a addressing faculty");
        }
      }
    } catch (error) {
      toast.error(error);
    }
    //   if((examTypeList==='1'&&college_Executive && college_Executive.length>0 &&addressing_faculty && addressing_faculty.length>0) ||(examTypeList==='2'&&addressing_faculty && addressing_faculty.length>0 &&college_Executive && college_Executive.length>0 ) ){
    //     await AxiosInstance.post("/addressing", formData, config)
    //     .then((response) => {
    //       toast.success("Successfully Addressing is submitted");
    //       setTimeout(() => {
    //         if (ex === "x") {
    //           navigate("/assign-quiz?to=2", { state: response.data.data });
    //         } else if (ex === "y") {
    //           navigate("/");
    //         }
    //         // navigate("/");
    //         // navigate("/quizTree");
    //       }, 1600);
    //     })
    //     .catch((error) => {
    //       toast.error(error.response.data.error);
    //     });
    //   }else{
    //     if(examTypeList===""){
    //       toast.error('please select mode of addressing')
    //     }else if(examTypeList==='1'){
    //       toast.error('please select a college executive and addressing faculty ')
    //     }else{
    //       toast.error('please select a addressing faculty and college executive')
    //     }
    //   }
    // } catch (error) {
    //   toast.error(error);
    // }
  };

  //! ================================================API CALLS=============================================================

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (videoSearch !== "") {
        let data = await AxiosInstance.get(
          `/videos?video_type_id=1&search_keyword=${videoSearch}&limit=10`
        );
        let finalData = data.data.data;
        setVideoOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/videos?video_type_id=1&limit=10`);
        let finalData = data.data.data;
        setVideoOption(finalData);
      }
    };

    fetchData();
  }, [videoSearch]);

  useEffect(() => {
    let fetchData = async (e) => {
      // College appointment rounds api
      let appointmentRounds = await AxiosInstance.get(
        `/college_appointment_rounds`
      );
      let finalColgRoundsData = appointmentRounds.data.data;
      setAppRoundsOptions(finalColgRoundsData);
    };
    fetchData();
  }, []);

  //get addressing faculty

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchAddressingFaculty !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setAddFacultyOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setAddFacultyOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchAddressingFaculty]);

  //get Executives

  useEffect(() => {
    let fetchData = async () => {
      // College Executive api
      if (searchCollegeExecutive !== "") {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&search_keyword=${searchCollegeExecutive}&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        if (collegeExecutiveData) {
          setCollegeExecutiveOptions(collegeExecutiveData);
        }
      } else {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        setCollegeExecutiveOptions(collegeExecutiveData);
      }
    };
    fetchData();
  }, [searchCollegeExecutive]);

  ///Selecting Appointment Based on Appointment Date
  const [inputValue, setInputValue] = useState([]);
  const [defaultCourseList1, setDefaultCourseList1] = useState([]);

  useEffect(() => {
    if (addressing_dateF && unitNameData) {
      fetchData1();
    } else {
      setColgOptions_1([]);
      setDefaultCourseList1([]);
    }
  }, [addressing_dateF, unitNameData]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  const fetchData1 = async () => {
    try {
      let response = await AxiosInstance.get(
        `/appointment_mini?appointment_date=${addressing_dateF}&unit_id=${unitNameData.ID}&limit=10`
      );
      let finalData = response.data.data;
      if (Array.isArray(finalData)) {
        setColgOptions_1(finalData);
        setDefaultCourseList1(finalData);
      } else {
        setColgOptions_1([]);
        setDefaultCourseList1([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setColgOptions_1([]);
      setDefaultCourseList1([]);
    }
  };

  const fetchOptions = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/appointment_mini?appointment_date=${addressing_dateF}&unit_id=${unitNameData.ID}&search_keyword=${searchTerm_1}&limit=10`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setColgOptions_1(data);
      } else {
        setColgOptions_1([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setColgOptions_1([]);
    }
  };

  const handleAddDateF = (e) => {
    const newDate = e.target.value;
    setAddressing_dateF(newDate);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions(newInputValue);
    } else {
      setColgOptions_1(defaultCourseList1);
    }
  };

  const handleOptionSelect = (_, newValue, action) => {
    setSelectedColgOptions_1(newValue);
  };

  ///Selecting Appointment Based on Appointment Time
  const [inputValue2, setInputValue2] = useState([]);
  const [defaultCourseList2, setDefaultCourseList2] = useState([]);

  // let fetchData2 = async () => {
  //   let response = await AxiosInstance.get(
  //     `/appointment_mini?appointment_time=${addressing_timeF}&limit=10`
  //   );
  //   let finalData = response.data.data;
  //   setColgOptions_2(finalData);
  //   setDefaultCourseList2(finalData);
  // };
  // const fetchOptions2 = async (searchTerm_1) => {
  //   try {
  //     const response = await AxiosInstance.get(
  //       `/appointment_mini?appointment_time=${addressing_timeF}&search_keyword=${searchTerm_1}&limit=10`
  //     );
  //     const data = response.data.data;
  //     if (Array.isArray(data)) {
  //       setColgOptions_2(data);
  //     } else {
  //       setColgOptions_2([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleInputChange2 = (event, newInputValue) => {
  //   setInputValue2(newInputValue);
  //   if (newInputValue.length >= 3) {
  //     fetchOptions2(newInputValue);
  //   } else {
  //     setColgOptions_2(defaultCourseList2);
  //   }
  // };

  // const handleOptionSelect2 = (_, newValue, action) => {
  //   setSelectedColgOptions_1(newValue);
  // };

  useEffect(() => {
    if (addressing_dateF) {
      fetchData1();
    } else {
      setColgOptions_1([]);
      setDefaultCourseList1([]);
    }
  }, [addressing_dateF]);

  const fetchData2 = async () => {
    try {
      let response = await AxiosInstance.get(
        `/appointment_mini?appointment_time=${addressing_timeF}&limit=10`
      );
      let finalData = response.data.data;
      if (Array.isArray(finalData)) {
        setColgOptions_2(finalData);
        setDefaultCourseList2(finalData);
      } else {
        setColgOptions_2([]);
        setDefaultCourseList2([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setColgOptions_2([]);
      setDefaultCourseList2([]);
    }
  };

  const fetchOptions2 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/appointment_mini?appointment_time=${addressing_timeF}&search_keyword=${searchTerm_1}&limit=10`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setColgOptions_2(data);
      } else {
        setColgOptions_2([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setColgOptions_2([]);
    }
  };

  const handleAddTimeF = (e) => {
    const newDate = e.target.value;
    setAddressing_timeF(newDate);
  };

  const handleInputChange2 = (event, newInputValue) => {
    setInputValue2(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions2(newInputValue);
    } else {
      setColgOptions_2(defaultCourseList2);
    }
  };

  const handleOptionSelect2 = (_, newValue, action) => {
    setSelectedColgOptions_1(newValue);
  };

  //Selecting All Appointments
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let response = await AxiosInstance.get(`/appointment_mini?limit=10`);
  //       let finalData = response.data.data;
  //       if (Array.isArray(finalData)) {
  //         setColgOptions(finalData);
  //         setDefaultCourseList(finalData);
  //       } else {
  //         console.error("Data is not an array:", finalData);
  //         setColgOptions([]);
  //         setDefaultCourseList([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setColgOptions([]);
  //       setDefaultCourseList([]);
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Conditionally include `unit_id` in the API call based on `selectedUnitId`
        const endpoint = unitNameData
          ? `/appointment_mini?unit_id=${unitNameData.ID}&limit=10`
          : `/appointment_mini?limit=10`;
        let response = await AxiosInstance.get(endpoint);
        let finalData = response.data.data;
        if (Array.isArray(finalData)) {
          setColgOptions(finalData);
          setDefaultCourseList(finalData);
        } else {
          console.error("Data is not an array:", finalData);
          setColgOptions([]);
          setDefaultCourseList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setColgOptions([]);
        setDefaultCourseList([]);
      }
    };
    fetchData();
  }, [unitNameData]); // Re-fetch data when `selectedUnitId` changes

  // const fetchOptions1 = async (searchTerm_1) => {
  //   try {
  //     const response = await AxiosInstance.get(
  //       `/appointment_mini?search_keyword=${searchTerm_1}&limit=10`
  //     );
  //     const data = response.data.data;
  //     if (Array.isArray(data)) {
  //       setColgOptions(data);
  //     } else {
  //       setColgOptions([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchOptions1 = async (searchTerm_1) => {
    try {
      // Conditionally include `unit_id` in the API call based on `selectedUnitId`
      const endpoint = unitNameData
        ? `/appointment_mini?unit_id=${unitNameData.ID}&search_keyword=${searchTerm_1}&limit=10`
        : `/appointment_mini?search_keyword=${searchTerm_1}&limit=10`;
      const response = await AxiosInstance.get(endpoint);
      const data = response.data.data;
      if (Array.isArray(data)) {
        setColgOptions(data);
      } else {
        setColgOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setColgOptions_1(defaultCourseList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedColgOptions(newValue);
  };

  useEffect(() => {
    let fetchData = async () => {
      let fecthID =
        selectedColgOptions.length > 0
          ? `id=${selectedColgOptions[selectedColgOptions.length - 1].id}`
          : "";
      let allAppointments = await AxiosInstance.get(
        `/appointment_mini?${fecthID}`
      );
    };
    if (selectedColgOptions.length > 0) {
      fetchData();
    }
  }, [selectedColgOptions]);

  //! ================================================API CALLS=============================================================

  // to handle Date and Time
  let handleAddDate = (e) => {
    setAddressing_date(e.target.value);
  };
  let handleAddTime = (e) => {
    setAddressing_time(e.target.value);
  };

  // let handleAddTimeF = (e) => {
  //   setAddressing_timeF(e.target.value);
  //   if (e.target.value) {
  //     fetchData2();
  //   }
  // };

  // Reset Data
  let resetData = (e) => {
    navigate("/create_collegeappointment");
  };

  const handleSendEmailChange1 = (event) => {
    setSend_email(event.target.checked);
  };
  const handleSendBroadCast = (event) => {
    setIsBroadCaster(event.target.checked);
  };
  const handleSendMsgChange1 = (event) => {
    SetSend_message(event.target.checked);
  };
  const handleAssignQuizChange1 = (event) => {
    setAssign_quiz(event.target.checked);
    // if (event.target.checked === true) {
    //   setOpenModal(true);
    // }
  };

  const checkSubmit = () => {
    if (assign_quiz) {
      handleSubmit("x");
    } else {
      setOpenModal(true);
    }
  };
  return (
    <div>
      <section style={{ width: "100%", height: "auto" }}>
        <article
          style={{
            width: "98%",
            height: "auto",
            margin: "0 auto",
            marginTop: "4.5%",
          }}
        >
          <Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                checkSubmit();
              }}
            >
              <Paper variant="outlined" style={{ border: "1px solid gray" }}>
                <Typography
                  sx={{
                    marginBottom: 2,
                    background: "#DDE6ED",
                    color: "#081f37",
                    margin: "0 auto",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    borderBottom: "2px solid #9BA4B5",
                    fontSize: "25px",
                  }}
                  // className={Styles.backgrounds}
                >
                  CREATE ADDRESSING FORM
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper sx={{ padding: 1 }}>
                      {/* forms */}
                      <section>
                        {/* parent box for date and time */}
                        <Box sx={{ display: "flex" }}>
                          {/* dateee */}
                          <Box
                            component={"section"}
                            sx={{
                              boxShadow:
                                " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                              padding: "2px",
                              marginBottom: "20px",
                              outline: "1px solid #D6E4E5",
                              width: "100%",
                              marginRight: "1%",
                              borderLeft: "6px solid #9BA4B5",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                // marginBottom: 2,
                                fontWeight: "bold",
                                color: "#00337C",
                              }}
                              xs={12}
                            >
                              Select Existing Appointment Date
                            </Typography>

                            <Grid container spacing={1}>
                              <Grid item md={2.5} xs={12}>
                                <Item>
                                  <TextField
                                    type="datetime-local"
                                    fullWidth
                                    id="addressingDate"
                                    label="Appointment Date"
                                    name="addressing_date"
                                    onFocus={(e) => (e.target.type = "date")}
                                    onBlur={(e) => (e.target.type = "text")}
                                    autoFocus
                                    value={addressing_dateF}
                                    onChange={handleAddDateF}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                </Item>
                              </Grid>
                              <Grid item md={2.5} xs={12}>
                                <Item
                                  onChange={(e) => {
                                    setSearchUnitName(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={unitNameOption}
                                    disabled={!addressing_dateF}
                                    value={unitNameData}
                                    name="unitNameOption"
                                    onChange={(event, newData_xy) => {
                                      setUnitNameData(newData_xy);
                                      setSelectedColgOptions([]);
                                      setSelectedColgOptions_1([]);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Unit Name"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                              <Grid item md={7} xs={12}>
                                <Item>
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    multiple
                                    disabled={!unitNameData}
                                    required
                                    value={selectedColgOptions_1}
                                    onChange={handleOptionSelect}
                                    onInputChange={handleInputChange}
                                    options={colgOptions_1}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText={
                                      colgOptions_1.length === 0
                                        ? "No options"
                                        : ""
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => (
                                        <Chip
                                          key={option.name + (index + 1)}
                                          label={option.name}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderOption={(props, option) => (
                                      <Tooltip title={option.name} arrow>
                                        <li {...props}>{option.name}</li>
                                      </Tooltip>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Appointments Based On Appointment Date"
                                        variant="outlined"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            borderColor: "rgba(0, 0, 0, 10)",
                                            "& fieldset": {
                                              borderColor: "rgba(0, 0, 0, 5)",
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>

                          {/* <Box
                            component={"section"}
                            sx={{
                              boxShadow:
                                " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                              padding: "2px",
                              marginBottom: "20px",
                              outline: "1px solid #D6E4E5",
                              // marginTop: "1%",
                              width: "50%",
                              borderLeft: "6px solid #9BA4B5",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                // marginBottom: 2,
                                fontWeight: "bold",
                                color: "#00337C",
                              }}
                              xs={12}
                            >
                              Select Existing Appointment Time
                            </Typography>

                            <Grid container spacing={1}>
                          
                              <Grid item md={4} xs={12}>
                                <Item>
                                  <TextField
                                    type="datetime-local"
                                    fullWidth
                                    id="addressingTime"
                                    label="Appointment Time"
                                    name="addressing_date"
                                    onFocus={(e) => (e.target.type = "time")}
                                    onBlur={(e) => (e.target.type = "text")}
                                    autoFocus
                                    value={addressing_timeF}
                                    onChange={handleAddTimeF}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                </Item>
                              </Grid>

                    
                              <Grid item md={8} xs={12}>
                               
                                <Item>
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    multiple
                                    required
                                    value={selectedColgOptions_2}
                                    onChange={handleOptionSelect2}
                                    onInputChange={handleInputChange2}
                                    options={colgOptions_2}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText={
                                      colgOptions_2.length === 0
                                        ? "No options"
                                        : ""
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => (
                                        <Chip
                                          key={option.name + (index + 1)}
                                          label={option.name}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderOption={(props, option) => (
                                      <Tooltip title={option.name} arrow>
                                        <li {...props}>{option.name}</li>
                                      </Tooltip>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Appointments Based On Appointment Time"
                                        variant="outlined"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            borderColor: "rgba(0, 0, 0, 10)",
                                            "& fieldset": {
                                              borderColor: "rgba(0, 0, 0, 5)",
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                 
                            </Grid>
                          </Box> */}
                        </Box>

                        <Box
                          component={"section"}
                          sx={{
                            boxShadow:
                              " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                            padding: "2px",
                            marginBottom: "20px",
                            outline: "1px solid #D6E4E5",
                            // marginTop: "1%",
                            borderLeft: "6px solid #9BA4B5",
                            borderRadius: "10px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            // variant="h5"
                            sx={{
                              // marginBottom: 2,
                              fontWeight: "bold",
                              color: "#00337C",
                            }}
                            xs={12}
                          >
                            All Appointments Block
                          </Typography>

                          {/* new code for all appointments */}
                          <Grid
                            container
                            spacing={2}
                            item
                            // sx={{ marginBottom: "30px" }}
                          >
                            <Grid item md={12} xs={12}>
                              <Item>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  multiple
                                  required
                                  value={selectedColgOptions}
                                  onChange={handleOptionSelect1}
                                  onInputChange={handleInputChange1}
                                  options={colgOptions}
                                  getOptionLabel={(option) => option.name}
                                  renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                      <Chip
                                        key={option.name + (index + 1)}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Multiple Appointments"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                          "& fieldset": {
                                            borderColor: "rgba(0, 0, 0, 5)",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          </Grid>
                        </Box>

                        <Grid container spacing={1} sx={{ marginTop: "0.5%" }}>
                          <Grid item md={1.8} xs={12}>
                            <TextField
                              required
                              type="datetime-local"
                              fullWidth
                              id="addressingDate"
                              label=" Addressing Date"
                              name="addressing_date"
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => (e.target.type = "text")}
                              autoFocus
                              value={addressing_date}
                              onChange={handleAddDate}
                              inputProps={{
                                min: new Date().toISOString().split("T")[0],
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item md={1.8} xs={12}>
                            <TextField
                              type="datetime-local"
                              fullWidth
                              required
                              id="addressingTime"
                              label="Addressing Time"
                              name="addressing_date"
                              onFocus={(e) => (e.target.type = "time")}
                              onBlur={(e) => (e.target.type = "text")}
                              autoFocus
                              value={addressing_time}
                              onChange={handleAddTime}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ marginTop: "-1.3%" }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Mode Of Addressing
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                required
                                displayEmpty
                                className="my-select"
                                inputProps={{
                                  "aria-label": "Select an option",
                                }}
                                value={examTypeList}
                                // name="examTypeList"
                                onChange={(e) => {
                                  setExamTypeList(e.target.value);
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                }}
                              >
                                <MenuItem value="" disabled>
                                  <span className="my-select-placeholder">
                                    Select Mode
                                  </span>
                                </MenuItem>
                                {Object.entries(examTypeListOption).map(
                                  ([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                      {value}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            md={3.2}
                            onChange={(e) => {
                              setSearchCollegeExecutive(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              options={
                                collegeExecutiveOptions
                                  ? collegeExecutiveOptions
                                  : []
                              }
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.full_name}
                              renderOption={(props, option) => (
                                <li {...props}>{option.full_name}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Multiple College Executive"
                                  placeholder="Select Multiple College Executive"
                                  required={college_Executive.length === 0}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                              value={college_Executive}
                              onChange={(e, value) => {
                                setCollege_Executive(value);
                              }}
                              disabled={examTypeList == 2}
                            />
                          </Grid>
                          <Grid
                            item
                            md={3.2}
                            onChange={(e) => {
                              setSearchAddressingFaculty(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              options={
                                addFacultyOptions ? addFacultyOptions : []
                              }
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.full_name}
                              renderOption={(props, option) => (
                                <li {...props}>{option.full_name}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Select Multiple Addressing Faculty"
                                  placeholder="Select Multiple Addressing Faculty"
                                  required={addressing_faculty.length === 0}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              )}
                              value={addressing_faculty}
                              onChange={(e, value) => {
                                setAddressing_faculty(value);
                              }}
                              disabled={examTypeList == 1}
                            />
                          </Grid>
                          {/* <Grid
                          item
                          md={3}
                          onChange={(e) => {
                            setVideoSearch(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={videoOption}
                            value={videoData}
                            name="videoOption"
                            required
                            onChange={(event, newData_xy) => {
                              setVideoData(newData_xy);
                              if (newData_xy) {
                                setVideoError(false); 
                              }
                            }}
                            getOptionLabel={(option) => option.title}
                            disabled={!is_broadcaster}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Video"
                                variant="outlined"
                                error={videoError} 
                                helperText={
                                  videoError
                                    ? "Video is required when broadcaster is selected"
                                    : ""
                                }
                              />
                            )}
                          />
                        </Grid> */}
                          <Grid item md={12} xs={12}>
                            <h3>Interview Rounds</h3>
                          </Grid>

                          <Grid container spacing={1} item>
                            {valuesAdd.map((item, ind) => (
                              <Grid
                                item
                                md={2.75}
                                style={{
                                  display: "inline-flex",
                                  position: "relative",
                                  // marginRight: "10px",
                                }}
                              >
                                <FormControl
                                  style={{ width: "100%" }}
                                  key={ind + 1}
                                >
                                  <InputLabel
                                    id={`demo-simple-select-label-${ind}`} // Set a unique ID for each label
                                    sx={{
                                      textAlign: "left",
                                      fontWeight: "600",
                                      color: "#FC7300",
                                    }}
                                  >
                                    {`Round ${ind + 1}`}{" "}
                                    {/* Update the label text here */}
                                  </InputLabel>

                                  <Select
                                    labelId={`demo-simple-select-label-${ind}`} // Reference the label ID
                                    id="demo-simple-select"
                                    value={item}
                                    onChange={(event) =>
                                      handleValueChange(event.target.value, ind)
                                    }
                                    displayEmpty
                                    className="my-select"
                                    inputProps={{
                                      "aria-label": "Select an option",
                                    }}
                                    required
                                    sx={{
                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                        },
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                    }}
                                  >
                                    <MenuItem value="" disabled>
                                      <span className="my-select-placeholder">
                                        Select Rounds
                                      </span>
                                    </MenuItem>
                                    {appRoundsOptions.map((roundsData, i) => (
                                      <MenuItem
                                        key={roundsData.ID}
                                        value={roundsData.ID}
                                      >
                                        {roundsData.name}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                  {valuesAdd.length !== 1 && (
                                    <Button
                                      style={{
                                        position: "absolute",
                                        bottom: "-22px",
                                        right: "40%",
                                      }}
                                      onClick={() => removeRound(ind)}
                                    >
                                      <FaRegTrashAlt style={{ color: "red" }} />
                                    </Button>
                                  )}
                                  {valuesAdd.length - 1 === ind && (
                                    <Grid
                                      item
                                      md={1}
                                      style={{
                                        display:
                                          valuesAdd.length > 4
                                            ? "none"
                                            : "inline-flex",
                                        position: "absolute",
                                        right: "-4px",
                                        top: "25%",
                                      }}
                                    >
                                      <Button onClick={addRound}>
                                        <FaPlus />
                                      </Button>
                                    </Grid>
                                  )}
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>

                          {/* trialll */}

                          <Grid
                            item
                            md={12}
                            style={{ marginTop: "0.5%" }}
                            xs={12}
                          >
                            <Grid container spacing={2} xs={12}>
                              <Grid item xs={12} md={1.5}>
                                <Checkbox
                                  name="send_message"
                                  checked={send_message}
                                  onChange={handleSendMsgChange1}
                                  id="send_msg"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor="sendMsg"
                                  style={{ fontSize: "16px" }}
                                >
                                  Send Message
                                </label>
                              </Grid>
                              <Grid item xs={12} md={1.5}>
                                <Checkbox
                                  name="send_email"
                                  checked={send_email}
                                  onChange={handleSendEmailChange1}
                                  id="send_mail"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />{" "}
                                <label
                                  htmlFor="sendMail"
                                  style={{ fontSize: "16px" }}
                                >
                                  Send Mail
                                </label>
                              </Grid>
                              <Grid item xs={12} md={1.5}>
                                <Checkbox
                                  name="is_broadcaster"
                                  checked={is_broadcaster}
                                  onChange={handleSendBroadCast}
                                  id="is_broadcaster"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                  // disabled={examTypeList == 1}
                                />{" "}
                                <label
                                  htmlFor="broadcaster"
                                  style={{ fontSize: "16px" }}
                                >
                                  Video BroadCasting
                                </label>
                              </Grid>
                              <Grid
                                item
                                md={3}
                                onChange={(e) => {
                                  setVideoSearch(e.target.value);
                                }}
                              >
                                <Autocomplete
                                  options={videoOption}
                                  value={videoData}
                                  name="videoOption"
                                  onChange={(event, newData_xy) => {
                                    setVideoData(newData_xy);
                                    if (newData_xy) {
                                      setVideoError(false);
                                    }
                                  }}
                                  getOptionLabel={(option) => option.title}
                                  disabled={!is_broadcaster}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Search Video"
                                      variant="outlined"
                                      error={videoError}
                                      required={is_broadcaster}
                                      helperText={
                                        videoError
                                          ? "Video is required when broadcaster is selected"
                                          : ""
                                      }
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                          "& fieldset": {
                                            borderColor: "rgba(0, 0, 0, 5)",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            style={{ marginTop: "-0.5%" }}
                          >
                            <Grid item>
                              <Checkbox
                                name="assign_quiz"
                                checked={assign_quiz}
                                // checked={openModal}
                                onChange={handleAssignQuizChange1}
                                id="assign_quiz"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />{" "}
                              <label
                                htmlFor="assignQuiz"
                                style={{ fontSize: "20px", fontWeight: "bold" }}
                              >
                                Assign Quiz
                              </label>
                            </Grid>
                            <Modal
                              open={openModal}
                              onClose={handleCloseModal}
                              BackdropProps={{
                                style: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                },
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "30%",
                                  minHeight: "20vh",
                                  maxHeight: "90%", // Set your desired max height here
                                  backgroundColor: "white",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  borderRadius: "5px",
                                  padding: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  overflowY: "auto", // Add overflowY for scrolling if content exceeds the max height
                                }}
                              >
                                <h3 style={{ textAlign: "center" }}>
                                  Do you want to continue without assigning the
                                  quiz?
                                </h3>
                                <div>
                                  <button
                                    style={{
                                      background: "crimson",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      padding: "8px 20px",
                                      border: "none",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() => setOpenModal(false)}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    style={{
                                      background: "green",
                                      color: "#fff",
                                      fontWeight: "bold",
                                      border: "none",
                                      padding: "8px 20px",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() => handleSubmit("y")}
                                  >
                                    Proceed
                                  </button>
                                </div>
                              </div>
                            </Modal>
                          </Grid>
                          <Grid
                            xs={12}
                            item
                            spacing={1}
                            // sx={{ mt: 5, mb: 2 }}
                            style={{
                              textAlign: "right",
                              // padding: "2px",
                            }}
                          >
                            <Button
                              onClick={resetData}
                              type="button"
                              variant="contained"
                              style={{
                                width: "10%",
                                marginRight: "3%",
                                backgroundColor: "#E74646",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              style={{
                                width: "10%",
                                backgroundColor: "#1976D2",
                                fontWeight: "bold",
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </section>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Box>
        </article>
      </section>
    </div>
  );
};

export default NewCreateAddressingForm;

