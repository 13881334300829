import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Paper,
  Autocomplete,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  TableRow,
  Checkbox,
  IconButton,
  Chip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import moment from "moment";
import AxiosInstance from "../apis/AxiosInstance";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDE6ED",
    color: "#111",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function LeadPassingForm() {
  const Navigate = useNavigate();

  const [name, setName] = useState("");
  const [mobiles, setmobiles] = useState("");
  const [comment, setComment] = useState("");
  const [emails, setEmails] = useState("");
  const [load, setLoad] = useState(false);
  const [leadOption, setLeadOption] = useState([]);
  // const [leadData, setLeadData] = useState(null);
  const [leadData, setLeadData] = useState({ id: null, name: "" });
  const [defaultLead, setDefaultLead] = useState([]);
  const [searchLead, setSearchLead] = useState("");

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [defaultState, setDefaultState] = useState([]);

  const [stateOption, setStateOption] = useState([]);
  const [stateData, setStateData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [collegeData, setCollegeData] = useState("");

  const [companyName, setCompanyName] = useState("");

  // const [isBranchValid, setIsBranchValid] = useState(true);

  // const handleLeadCheckboxChange = (id, name) => {
  //   setLeadData({ id, name }); // Store both the selected lead ID and name
  // };

  // const handleLeadCheckboxChange = (id, name) => {
  //   if (leadData.id === id) {
  //     setLeadData({ id: null, name: "" });
  //   } else {
  //     setLeadData({ id, name });
  //   }
  // };

  const handleLeadCheckboxChange = (id, name) => {
    if (leadData.id === id) {
      // Unselect lead if it was already selected
      setLeadData({ id: null, name: "" });
    } else {
      // Clear branches if switching from lead ID 1 to another
      if (leadData.id === 1 && id !== 1) {
        setSelectedBranchOptions([]);
      }
      if (leadData.id === 2 && id !== 2) {
        setCompanyName("");
      }
      if (leadData.id === 3 && id !== 3) {
        setStateData(null);
        setCollegeData("");
      }
      // Update lead data
      setLeadData({ id, name });
    }
  };

  // const handleLeadCheckboxChange = (id, name) => {
  //   if (leadData.id === id) {
  //     setLeadData({ id: null, name: "" });
  //     // setIsBranchValid(true); // Reset validity if lead is deselected
  //   } else {
  //     setLeadData({ id, name });
  //     // setIsBranchValid(id !== 1 || selectedBranchOptions.length > 0); // Set validity based on lead id
  //   }
  // };

  const countries = [
    {
      name: "Afghanistan",
      id: "+93",
      label: "+93 Afghanistan",
    },
    {
      name: "Albania",
      id: "+355",
      label: "+355 Albania",
    },
    {
      name: "Algeria",
      id: "+213",
      label: "+213 Algeria",
    },
    {
      name: "American Samoa",
      id: "+1-684",
      label: "+1-684 American Samoa",
    },
    {
      name: "Andorra",
      id: "+376",
      label: "+376 Andorra",
    },
    {
      name: "Angola",
      id: "+244",
      label: "+244 Angola",
    },
    {
      name: "Anguilla",
      id: "+1-264",
      label: "+1-264 Anguilla",
    },
    {
      name: "Antarctica",
      id: "+672",
      label: "+672 Antarctica",
    },
    {
      name: "Antigua and Barbuda",
      id: "+1-268",
      label: "+1-268 Antigua and Barbuda",
    },
    {
      name: "Argentina",
      id: "+54",
      label: "+54 Argentina",
    },
    {
      name: "Armenia",
      id: "+374",
      label: "+374 Armenia",
    },
    {
      name: "Aruba",
      id: "+297",
      label: "+297 Aruba",
    },
    {
      name: "Australia",
      id: "+61",
      label: "+61 Australia",
    },
    {
      name: "Austria",
      id: "+43",
      label: "+43 Austria",
    },
    {
      name: "Azerbaijan",
      id: "+994",
      label: "+994 Azerbaijan",
    },
    {
      name: "Bahamas",
      id: "+1-242",
      label: "+1-242 Bahamas",
    },
    {
      name: "Bahrain",
      id: "+973",
      label: "+973 Bahrain",
    },
    {
      name: "Bangladesh",
      id: "+880",
      label: "+880 Bangladesh",
    },
    {
      name: "Barbados",
      id: "+1-246",
      label: "+1-246 Barbados",
    },
    {
      name: "Belarus",
      id: "+375",
      label: "+375 Belarus",
    },
    {
      name: "Belgium",
      id: "+32",
      label: "+32 Belgium",
    },
    {
      name: "Belize",
      id: "+501",
      label: "+501 Belize",
    },
    {
      name: "Benin",
      id: "+229",
      label: "+229 Benin",
    },
    {
      name: "Bermuda",
      id: "+1-441",
      label: "+1-441 Bermuda",
    },
    {
      name: "Bhutan",
      id: "+975",
      label: "+975 Bhutan",
    },
    {
      name: "Bolivia",
      id: "+591",
      label: "+591 Bolivia",
    },
    {
      name: "Bosnia and Herzegovina",
      id: "+387",
      label: "+387 Bosnia and Herzegovina",
    },
    {
      name: "Botswana",
      id: "+267",
      label: "+267 Botswana",
    },
    {
      name: "Brazil",
      id: "+55",
      label: "+55 Brazil",
    },
    {
      name: "British Indian Ocean Territory",
      id: "+246",
      label: "+246 British Indian Ocean Territory",
    },
    {
      name: "British Virgin Islands",
      id: "+1-284",
      label: "+1-284 British Virgin Islands",
    },
    {
      name: "Brunei",
      id: "+673",
      label: "+673 Brunei",
    },
    {
      name: "Bulgaria",
      id: "+359",
      label: "+359 Bulgaria",
    },
    {
      name: "Burkina Faso",
      id: "+226",
      label: "+226 Burkina Faso",
    },
    {
      name: "Burundi",
      id: "+257",
      label: "+257 Burundi",
    },
    {
      name: "Cabo Verde",
      id: "+238",
      label: "+238 Cabo Verde",
    },
    {
      name: "Cambodia",
      id: "+855",
      label: "+855 Cambodia",
    },
    {
      name: "Cameroon",
      id: "+237",
      label: "+237 Cameroon",
    },
    {
      name: "Canada",
      id: "+1",
      label: "+1 Canada",
    },
    {
      name: "Cayman Islands",
      id: "+1-345",
      label: "+1-345 Cayman Islands",
    },
    {
      name: "Central African Republic",
      id: "+236",
      label: "+236 Central African Republic",
    },
    {
      name: "Chad",
      id: "+235",
      label: "+235 Chad",
    },
    {
      name: "Chile",
      id: "+56",
      label: "+56 Chile",
    },
    {
      name: "China",
      id: "+86",
      label: "+86 China",
    },
    {
      name: "Colombia",
      id: "+57",
      label: "+57 Colombia",
    },
    {
      name: "Comoros",
      id: "+269",
      label: "+269 Comoros",
    },
    {
      name: "Congo (Congo-Brazzaville)",
      id: "+242",
      label: "+242 Congo (Congo-Brazzaville)",
    },
    {
      name: "Congo (DRC)",
      id: "+243",
      label: "+243 Congo (DRC)",
    },
    {
      name: "Cook Islands",
      id: "+682",
      label: "+682 Cook Islands",
    },
    {
      name: "Costa Rica",
      id: "+506",
      label: "+506 Costa Rica",
    },
    {
      name: "Croatia",
      id: "+385",
      label: "+385 Croatia",
    },
    {
      name: "Cuba",
      id: "+53",
      label: "+53 Cuba",
    },
    {
      name: "Curaçao",
      id: "+599",
      label: "+599 Curaçao",
    },
    {
      name: "Cyprus",
      id: "+357",
      label: "+357 Cyprus",
    },
    {
      name: "Czech Republic",
      id: "+420",
      label: "+420 Czech Republic",
    },
    {
      name: "Denmark",
      id: "+45",
      label: "+45 Denmark",
    },
    {
      name: "Djibouti",
      id: "+253",
      label: "+253 Djibouti",
    },
    {
      name: "Dominica",
      id: "+1-767",
      label: "+1-767 Dominica",
    },
    {
      name: "Dominican Republic",
      id: "+1-809",
      label: "+1-809 Dominican Republic",
    },
    {
      name: "East Timor",
      id: "+670",
      label: "+670 East Timor",
    },
    {
      name: "Ecuador",
      id: "+593",
      label: "+593 Ecuador",
    },
    {
      name: "Egypt",
      id: "+20",
      label: "+20 Egypt",
    },
    {
      name: "El Salvador",
      id: "+503",
      label: "+503 El Salvador",
    },
    {
      name: "Equatorial Guinea",
      id: "+240",
      label: "+240 Equatorial Guinea",
    },
    {
      name: "Eritrea",
      id: "+291",
      label: "+291 Eritrea",
    },
    {
      name: "Estonia",
      id: "+372",
      label: "+372 Estonia",
    },
    {
      name: "Eswatini",
      id: "+268",
      label: "+268 Eswatini",
    },
    {
      name: "Ethiopia",
      id: "+251",
      label: "+251 Ethiopia",
    },
    {
      name: "Fiji",
      id: "+679",
      label: "+679 Fiji",
    },
    {
      name: "Finland",
      id: "+358",
      label: "+358 Finland",
    },
    {
      name: "France",
      id: "+33",
      label: "+33 France",
    },
    {
      name: "French Guiana",
      id: "+594",
      label: "+594 French Guiana",
    },
    {
      name: "French Polynesia",
      id: "+689",
      label: "+689 French Polynesia",
    },
    {
      name: "Gabon",
      id: "+241",
      label: "+241 Gabon",
    },
    {
      name: "Gambia",
      id: "+220",
      label: "+220 Gambia",
    },
    {
      name: "Georgia",
      id: "+995",
      label: "+995 Georgia",
    },
    {
      name: "Germany",
      id: "+49",
      label: "+49 Germany",
    },
    {
      name: "Ghana",
      id: "+233",
      label: "+233 Ghana",
    },
    {
      name: "Gibraltar",
      id: "+350",
      label: "+350 Gibraltar",
    },
    {
      name: "Greece",
      id: "+30",
      label: "+30 Greece",
    },
    {
      name: "Greenland",
      id: "+299",
      label: "+299 Greenland",
    },
    {
      name: "Grenada",
      id: "+1-473",
      label: "+1-473 Grenada",
    },
    {
      name: "Guadeloupe",
      id: "+590",
      label: "+590 Guadeloupe",
    },
    {
      name: "Guam",
      id: "+1-671",
      label: "+1-671 Guam",
    },
    {
      name: "Guatemala",
      id: "+502",
      label: "+502 Guatemala",
    },
    {
      name: "Guinea",
      id: "+224",
      label: "+224 Guinea",
    },
    {
      name: "Guinea-Bissau",
      id: "+245",
      label: "+245 Guinea-Bissau",
    },
    {
      name: "Guyana",
      id: "+592",
      label: "+592 Guyana",
    },
    {
      name: "Haiti",
      id: "+509",
      label: "+509 Haiti",
    },
    {
      name: "Honduras",
      id: "+504",
      label: "+504 Honduras",
    },
    {
      name: "Hong Kong",
      id: "+852",
      label: "+852 Hong Kong",
    },
    {
      name: "Hungary",
      id: "+36",
      label: "+36 Hungary",
    },
    {
      name: "Iceland",
      id: "+354",
      label: "+354 Iceland",
    },
    {
      name: "India",
      id: "+91",
      label: "+91 India",
    },
    {
      name: "Indonesia",
      id: "+62",
      label: "+62 Indonesia",
    },
    {
      name: "Iran",
      id: "+98",
      label: "+98 Iran",
    },
    {
      name: "Iraq",
      id: "+964",
      label: "+964 Iraq",
    },
    {
      name: "Ireland",
      id: "+353",
      label: "+353 Ireland",
    },
    {
      name: "Israel",
      id: "+972",
      label: "+972 Israel",
    },
    {
      name: "Italy",
      id: "+39",
      label: "+39 Italy",
    },
    {
      name: "Ivory Coast",
      id: "+225",
      label: "+225 Ivory Coast",
    },
    {
      name: "Jamaica",
      id: "+1-876",
      label: "+1-876 Jamaica",
    },
    {
      name: "Japan",
      id: "+81",
      label: "+81 Japan",
    },
    {
      name: "Jordan",
      id: "+962",
      label: "+962 Jordan",
    },
    {
      name: "Kazakhstan",
      id: "+7",
      label: "+7 Kazakhstan",
    },
    {
      name: "Kenya",
      id: "+254",
      label: "+254 Kenya",
    },
    {
      name: "Kiribati",
      id: "+686",
      label: "+686 Kiribati",
    },
    {
      name: "Kuwait",
      id: "+965",
      label: "+965 Kuwait",
    },
    {
      name: "Kyrgyzstan",
      id: "+996",
      label: "+996 Kyrgyzstan",
    },
    {
      name: "Laos",
      id: "+856",
      label: "+856 Laos",
    },
    {
      name: "Latvia",
      id: "+371",
      label: "+371 Latvia",
    },
    {
      name: "Lebanon",
      id: "+961",
      label: "+961 Lebanon",
    },
    {
      name: "Lesotho",
      id: "+266",
      label: "+266 Lesotho",
    },
    {
      name: "Liberia",
      id: "+231",
      label: "+231 Liberia",
    },
    {
      name: "Libya",
      id: "+218",
      label: "+218 Libya",
    },
    {
      name: "Liechtenstein",
      id: "+423",
      label: "+423 Liechtenstein",
    },
    {
      name: "Lithuania",
      id: "+370",
      label: "+370 Lithuania",
    },
    {
      name: "Luxembourg",
      id: "+352",
      label: "+352 Luxembourg",
    },
    {
      name: "Macao",
      id: "+853",
      label: "+853 Macao",
    },
    {
      name: "Madagascar",
      id: "+261",
      label: "+261 Madagascar",
    },
    {
      name: "Malawi",
      id: "+265",
      label: "+265 Malawi",
    },
    {
      name: "Malaysia",
      id: "+60",
      label: "+60 Malaysia",
    },
    {
      name: "Maldives",
      id: "+960",
      label: "+960 Maldives",
    },
    {
      name: "Mali",
      id: "+223",
      label: "+223 Mali",
    },
    {
      name: "Malta",
      id: "+356",
      label: "+356 Malta",
    },
    {
      name: "Marshall Islands",
      id: "+692",
      label: "+692 Marshall Islands",
    },
    {
      name: "Martinique",
      id: "+596",
      label: "+596 Martinique",
    },
    {
      name: "Mauritania",
      id: "+222",
      label: "+222 Mauritania",
    },
    {
      name: "Mauritius",
      id: "+230",
      label: "+230 Mauritius",
    },
    {
      name: "Mexico",
      id: "+52",
      label: "+52 Mexico",
    },
    {
      name: "Micronesia",
      id: "+691",
      label: "+691 Micronesia",
    },
    {
      name: "Moldova",
      id: "+373",
      label: "+373 Moldova",
    },
    {
      name: "Monaco",
      id: "+377",
      label: "+377 Monaco",
    },
    {
      name: "Mongolia",
      id: "+976",
      label: "+976 Mongolia",
    },
    {
      name: "Montenegro",
      id: "+382",
      label: "+382 Montenegro",
    },
    {
      name: "Montserrat",
      id: "+1-664",
      label: "+1-664 Montserrat",
    },
    {
      name: "Morocco",
      id: "+212",
      label: "+212 Morocco",
    },
    {
      name: "Mozambique",
      id: "+258",
      label: "+258 Mozambique",
    },
    {
      name: "Myanmar",
      id: "+95",
      label: "+95 Myanmar",
    },
    {
      name: "Namibia",
      id: "+264",
      label: "+264 Namibia",
    },
    {
      name: "Nauru",
      id: "+674",
      label: "+674 Nauru",
    },
    {
      name: "Nepal",
      id: "+977",
      label: "+977 Nepal",
    },
    {
      name: "Netherlands",
      id: "+31",
      label: "+31 Netherlands",
    },
    {
      name: "New Caledonia",
      id: "+687",
      label: "+687 New Caledonia",
    },
    {
      name: "New Zealand",
      id: "+64",
      label: "+64 New Zealand",
    },
    {
      name: "Nicaragua",
      id: "+505",
      label: "+505 Nicaragua",
    },
    {
      name: "Niger",
      id: "+227",
      label: "+227 Niger",
    },
    {
      name: "Nigeria",
      id: "+234",
      label: "+234 Nigeria",
    },
    {
      name: "Niue",
      id: "+683",
      label: "+683 Niue",
    },
    {
      name: "Norfolk Island",
      id: "+672",
      label: "+672 Norfolk Island",
    },
    {
      name: "North Korea",
      id: "+850",
      label: "+850 North Korea",
    },
    {
      name: "North Macedonia",
      id: "+389",
      label: "+389 North Macedonia",
    },
    {
      name: "Northern Mariana Islands",
      id: "+1-670",
      label: "+1-670 Northern Mariana Islands",
    },
    {
      name: "Norway",
      id: "+47",
      label: "+47 Norway",
    },
    {
      name: "Oman",
      id: "+968",
      label: "+968 Oman",
    },
    {
      name: "Pakistan",
      id: "+92",
      label: "+92 Pakistan",
    },
    {
      name: "Palau",
      id: "+680",
      label: "+680 Palau",
    },
    {
      name: "Palestine",
      id: "+970",
      label: "+970 Palestine",
    },
    {
      name: "Panama",
      id: "+507",
      label: "+507 Panama",
    },
    {
      name: "Papua New Guinea",
      id: "+675",
      label: "+675 Papua New Guinea",
    },
    {
      name: "Paraguay",
      id: "+595",
      label: "+595 Paraguay",
    },
    {
      name: "Peru",
      id: "+51",
      label: "+51 Peru",
    },
    {
      name: "Philippines",
      id: "+63",
      label: "+63 Philippines",
    },
    {
      name: "Poland",
      id: "+48",
      label: "+48 Poland",
    },
    {
      name: "Portugal",
      id: "+351",
      label: "+351 Portugal",
    },
    {
      name: "Puerto Rico",
      id: "+1-787",
      label: "+1-787 Puerto Rico",
    },
    {
      name: "Qatar",
      id: "+974",
      label: "+974 Qatar",
    },
    {
      name: "Romania",
      id: "+40",
      label: "+40 Romania",
    },
    {
      name: "Russia",
      id: "+7",
      label: "+7 Russia",
    },
    {
      name: "Rwanda",
      id: "+250",
      label: "+250 Rwanda",
    },
    {
      name: "Saint Kitts and Nevis",
      id: "+1-869",
      label: "+1-869 Saint Kitts and Nevis",
    },
    {
      name: "Saint Lucia",
      id: "+1-758",
      label: "+1-758 Saint Lucia",
    },
    {
      name: "Saint Vincent and the Grenadines",
      id: "+1-784",
      label: "+1-784 Saint Vincent and the Grenadines",
    },
    {
      name: "Samoa",
      id: "+685",
      label: "+685 Samoa",
    },
    {
      name: "San Marino",
      id: "+378",
      label: "+378 San Marino",
    },
    {
      name: "São Tomé and Príncipe",
      id: "+239",
      label: "+239 São Tomé and Príncipe",
    },
    {
      name: "Saudi Arabia",
      id: "+966",
      label: "+966 Saudi Arabia",
    },
    {
      name: "Senegal",
      id: "+221",
      label: "+221 Senegal",
    },
    {
      name: "Serbia",
      id: "+381",
      label: "+381 Serbia",
    },
    {
      name: "Seychelles",
      id: "+248",
      label: "+248 Seychelles",
    },
    {
      name: "Sierra Leone",
      id: "+232",
      label: "+232 Sierra Leone",
    },
    {
      name: "Singapore",
      id: "+65",
      label: "+65 Singapore",
    },
    {
      name: "Sint Maarten",
      id: "+1-721",
      label: "+1-721 Sint Maarten",
    },
    {
      name: "Slovakia",
      id: "+421",
      label: "+421 Slovakia",
    },
    {
      name: "Slovenia",
      id: "+386",
      label: "+386 Slovenia",
    },
    {
      name: "Solomon Islands",
      id: "+677",
      label: "+677 Solomon Islands",
    },
    {
      name: "Somalia",
      id: "+252",
      label: "+252 Somalia",
    },
    {
      name: "South Africa",
      id: "+27",
      label: "+27 South Africa",
    },
    {
      name: "South Korea",
      id: "+82",
      label: "+82 South Korea",
    },
    {
      name: "South Sudan",
      id: "+211",
      label: "+211 South Sudan",
    },
    {
      name: "Spain",
      id: "+34",
      label: "+34 Spain",
    },
    {
      name: "Sri Lanka",
      id: "+94",
      label: "+94 Sri Lanka",
    },
    {
      name: "Sudan",
      id: "+249",
      label: "+249 Sudan",
    },
    {
      name: "Suriname",
      id: "+597",
      label: "+597 Suriname",
    },
    {
      name: "Sweden",
      id: "+46",
      label: "+46 Sweden",
    },
    {
      name: "Switzerland",
      id: "+41",
      label: "+41 Switzerland",
    },
    {
      name: "Syria",
      id: "+963",
      label: "+963 Syria",
    },
    {
      name: "Taiwan",
      id: "+886",
      label: "+886 Taiwan",
    },
    {
      name: "Tajikistan",
      id: "+992",
      label: "+992 Tajikistan",
    },
    {
      name: "Tanzania",
      id: "+255",
      label: "+255 Tanzania",
    },
    {
      name: "Thailand",
      id: "+66",
      label: "+66 Thailand",
    },
    {
      name: "Togo",
      id: "+228",
      label: "+228 Togo",
    },
    {
      name: "Tokelau",
      id: "+690",
      label: "+690 Tokelau",
    },
    {
      name: "Tonga",
      id: "+676",
      label: "+676 Tonga",
    },
    {
      name: "Trinidad and Tobago",
      id: "+1-868",
      label: "+1-868 Trinidad and Tobago",
    },
    {
      name: "Tunisia",
      id: "+216",
      label: "+216 Tunisia",
    },
    {
      name: "Turkey",
      id: "+90",
      label: "+90 Turkey",
    },
    {
      name: "Turkmenistan",
      id: "+993",
      label: "+993 Turkmenistan",
    },
    {
      name: "Tuvalu",
      id: "+688",
      label: "+688 Tuvalu",
    },
    {
      name: "Uganda",
      id: "+256",
      label: "+256 Uganda",
    },
    {
      name: "Ukraine",
      id: "+380",
      label: "+380 Ukraine",
    },
    {
      name: "United Arab Emirates",
      id: "+971",
      label: "+971 United Arab Emirates",
    },
    {
      name: "United Kingdom",
      id: "+44",
      label: "+44 United Kingdom",
    },
    {
      name: "United States",
      id: "+1",
      label: "+1 United States",
    },
    {
      name: "Uruguay",
      id: "+598",
      label: "+598 Uruguay",
    },
    {
      name: "Uzbekistan",
      id: "+998",
      label: "+998 Uzbekistan",
    },
    {
      name: "Vanuatu",
      id: "+678",
      label: "+678 Vanuatu",
    },
    {
      name: "Vatican City",
      id: "+39",
      label: "+39 Vatican City",
    },
    {
      name: "Venezuela",
      id: "+58",
      label: "+58 Venezuela",
    },
    {
      name: "Vietnam",
      id: "+84",
      label: "+84 Vietnam",
    },
    {
      name: "Yemen",
      id: "+967",
      label: "+967 Yemen",
    },
    {
      name: "Zambia",
      id: "+260",
      label: "+260 Zambia",
    },
    {
      name: "Zimbabwe",
      id: "+263",
      label: "+263 Zimbabwe",
    },
  ];

  // const [branchOption, setBranchOption] = useState([]);
  // const [branchData, setBranchData] = useState(null);
  // const [defaultBranch, setDefaultBranch] = useState([]);
  // const [searchBranch, setSearchBranch] = useState("");

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultBranchList, setDefaultBranchList] = useState([]);

  const parseLog = (a) => {
    const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
    const { id } = userData.role[0];
    return id.toString();
  };
  const role = parseLog(Cookies.get("logged"));

  const current_role = Cookies.get("current_role");

  const leadDescriptionMap = {
    1: "Want to know course information",
    2: "Any Company wants to hire our students on any technology (even if we are not training on that subject/course)",
    3: "College wants any kind of training from Qspiders at their campus (even if we are not training on that subject/course)",
    4: "Company or client wants training for their employees",
    5: "College asking to hire their students or wants any kind of training from Qspiders",
  };

  // const [countryCodeOption, setCountryCodeOption] = useState([]);
  // const [countryCodeData, setCountryCodeData] = useState(null);
  // const [defaultCountryCode, setDefaultCountryCode] = useState([]);
  // const [searchCountryCode, setSearchCountryCode] = useState("");

  // const [countryCodeOption, setCountryCodeOption] = useState(countries); // Use local array
  // const [countryCodeData, setCountryCodeData] = useState(null);
  // const [searchCountryCode, setSearchCountryCode] = useState("");

  // // Handle input change to filter countries based on search term
  // const handleInputCountryCodeChange = (event, searchTerm) => {
  //   if (searchTerm && searchTerm.length > 0) {
  //     const filteredOptions = countries.filter((country) =>
  //       country.label.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setCountryCodeOption(filteredOptions);
  //   } else {
  //     // Reset to full country list if the input is cleared
  //     setCountryCodeOption(countries);
  //   }
  //   setSearchCountryCode(searchTerm);
  // };

  // // Handle option selection from dropdown
  // const handleOptionSelect = (event, newValue) => {
  //   if (newValue) {
  //     setCountryCodeData(newValue); // Ensure newValue is set
  //   } else {
  //     setCountryCodeData(null); // Reset if no value is selected
  //   }
  // }

  const [countryCodeOption, setCountryCodeOption] = useState(
    countries.filter((country) => country.id === "+91") // Display only "+91 India" initially
  );
  const [countryCodeData, setCountryCodeData] = useState(
    countries.find((country) => country.id === "+91") // Set default to "+91 India"
  );
  const [searchCountryCode, setSearchCountryCode] = useState("");

  // Handle input change to filter countries based on search term
  const handleInputCountryCodeChange = (event, searchTerm) => {
    if (searchTerm && searchTerm.length > 0) {
      const filteredOptions = countries.filter((country) =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setCountryCodeOption(filteredOptions);
    } else {
      // Reset to full country list if the input is cleared
      setCountryCodeOption(countries);
    }
    setSearchCountryCode(searchTerm);
  };

  // Handle option selection
  const handleOptionSelect = (event, newValue) => {
    setCountryCodeData(newValue);
  };

  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmails(email);

    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  // const [files, setFiles] = useState([{ file: null }]);

  // const handleFileChange = (index, e) => {
  //   const newFiles = [...files];
  //   newFiles[index].file = e.target.files[0];
  //   setFiles(newFiles);
  // };

  // const addFileField = () => {
  //   setFiles([...files, { file: null }]);
  // };

  // const removeFileField = (index) => {
  //   const newFiles = [...files];
  //   newFiles.splice(index, 1);
  //   setFiles(newFiles);
  // };

  const [files, setFiles] = useState([{ id: Date.now(), file: null }]);

  const handleFileChange = (id, e) => {
    const newFiles = files.map((fileObj) =>
      fileObj.id === id ? { ...fileObj, file: e.target.files[0] } : fileObj
    );
    setFiles(newFiles);
  };

  const addFileField = () => {
    setFiles([...files, { id: Date.now(), file: null }]);
  };

  const removeFileField = (id) => {
    const newFiles = files.filter((fileObj) => fileObj.id !== id);
    setFiles(newFiles);
  };

  const [selectBranch, setSelectBranch] = useState(false);

  const handleSelectBranch = (event) => {
    setSelectBranch(event.target.checked);
  };

  const [selectedAudioNames, setSelectedAudioNames] = useState([]);

  const handleCheckboxChange = (audioName, isChecked) => {
    setSelectedAudioNames((prevSelectedAudioNames) => {
      if (isChecked) {
        // Add audioName to selectedAudioNames if it's checked
        return [...prevSelectedAudioNames, audioName];
      } else {
        // Remove audioName from selectedAudioNames if it's unchecked
        return prevSelectedAudioNames.filter((name) => name !== audioName);
      }
    });
  };

  const [callRecord_list, setCallRecord_list] = useState([]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersAndOnePeriodRegex = /^[a-zA-Z\s]*\.?[a-zA-Z\s]*$/;

    if (!lettersAndOnePeriodRegex.test(value)) {
      // If the input contains more than one period or non-letter characters, remove them
      setName(value.replace(/[^a-zA-Z\s.]/g, "").replace(/\.{2,}/g, "."));
    } else {
      setName(value);
    }
  };

  // useEffect(() => {
  //   if (mobiles.length === 10) {
  //     const fetchdata = async () => {
  //       setLoad(true);
  //       try {
  //         let data = await AxiosInstance.get(
  //           `/call_record?number=${mobiles}&limit=10`
  //         );
  //         let finalData = data.data.data;
  //         setCallRecord_list(finalData);
  //         setLoad(false);
  //       } catch (error) {
  //         console.error("Error fetching call records", error);
  //         setLoad(false);
  //       }
  //     };
  //     fetchdata();
  //   } else {
  //     // Clear the previous data if the mobile number is not 10 digits
  //     setCallRecord_list([]);
  //   }
  // }, [mobiles]);

  useEffect(() => {
    const fetchdata = async () => {
      setLoad(true); // Start loading
      try {
        let response = await AxiosInstance.get(`/get_lead?limit=10`);

        // Apply filters based on the role
        let finalData = response.data.items;
        if (role === "1" || role === "5") {
          finalData = finalData.filter((item) => item.id !== 5);
        } else if (current_role === "19" || current_role === "20") {
          finalData = finalData.filter((item) => item.id !== 3);
        }

        setLeadOption(finalData);
        setDefaultLead(finalData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoad(false); // Stop loading
      }
    };
    fetchdata();
  }, [role]);

  // useEffect(() => {
  //   let fetchdata = async (e) => {
  //     let data = await AxiosInstance.get(`/country_code?limit=10`);
  //     let finalData = data.data.data;
  //     setCountryCodeOption(finalData);
  //     setDefaultCountryCode(finalData);
  //   };
  //   fetchdata();
  // }, []);

  // const searchcountryCode = async (e) => {
  //   try {
  //     let data = await AxiosInstance.get(
  //       `/country_code?search_keyword=${e}&limit=10`
  //     );
  //     let finalData = data.data.data;
  //     setCountryCodeOption(finalData);
  //   } catch (error) {
  //     setCountryCodeOption(defaultCountryCode);
  //   }
  // };

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(`/branch?limit=10`);
      let finalData = response.data?.data || [];
      setBranchOptions(finalData);
      setDefaultBranchList(finalData);
    };
    fetchData();
  }, []);

  const fetchOptions1 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/branch?search_keyword=${searchTerm_1}&limit=10`
      );
      const data = response.data?.data || [];
      setBranchOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setBranchOptions([]);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setBranchOptions(defaultBranchList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedBranchOptions(newValue || []);
    // setIsBranchValid(
    //   !(leadData.id === 1 && (newValue?.length === 0 || !newValue))
    // );
  };

  // useEffect(() => {
  //   let fetchdata = async (e) => {
  //     let data = await AxiosInstance.get(`/branch?limit=10`);
  //     let finalData = data.data.data;
  //     setBranchOption(finalData);
  //     setDefaultBranch(finalData);
  //   };
  //   fetchdata();
  // }, []);

  // const searchbranch = async (e) => {
  //   try {
  //     let data = await AxiosInstance.get(
  //       `/branch?search_keyword=${e}&limit=10`
  //     );
  //     let finalData = data.data.data;
  //     setBranchOption(finalData);
  //   } catch (error) {
  //     setBranchOption(defaultBranch);
  //   }
  // };

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);
  //!fetching the apis
  useEffect(() => {
    const fetchData = async () => {
      if (countryMiniId !== 0) {
        try {
          const response = await AxiosInstance.get(
            `/statemini?limit=10&country_id=${countryMiniId}`
          );
          const finalStateData = response.data.data;
          setStateOption(finalStateData);
          setDefaultState(finalStateData);
        } catch (error) {
          console.error("Error fetching state data:", error);
        }
      }
    };
    fetchData();
  }, [countryMiniId]);

  // Search for states based on the search term
  const searchState = async (e) => {
    try {
      const response = await AxiosInstance.get(
        `/statemini?search_keyword=${e}&limit=10&country_id=${countryMiniId}`
      );
      const finalStateData = response.data.data;
      setStateOption(finalStateData);
    } catch (error) {
      console.error("Error fetching filtered state data:", error);
      setStateOption(defaultState);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!leadData.id) {
      toast.info("Lead is required");
      return;
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let formData = new FormData();

    const token = Cookies.get("logged");

    const tokenData = JSON.parse(token);

    const author = {
      id: tokenData.user_id || null,
      name: tokenData.name || "",
      roles:
        tokenData.role.map((role) => ({
          id: role.id,
          name: role.name,
        })) || [],
      branches: [],
    };

    // if (name) {
    //   formData.append("name", name);
    // }

    // formData.append("country_code", `+${countryCodeData.code}`);
    // formData.append("contact", mobiles ? mobiles : "");
    // if (emails) {
    //   formData.append("email", emails);
    // }
    // const leadObject = {
    //   id: leadData.id,
    //   name: leadData.name,
    // };

    // formData.append("lead_id", JSON.stringify(leadObject));

    // if (selectedBranchOptions && selectedBranchOptions.length > 0) {
    //   const branchArray = selectedBranchOptions.map((branch) => ({
    //     id: branch?.aid,
    //     name: branch?.name,
    //   }));

    //   formData.append("branch_id", JSON.stringify(branchArray));
    // }

    // if (comment) {
    //   formData.append("comment", comment);
    // }

    // files.forEach((fileObj, index) => {
    //   if (fileObj.file) {
    //     formData.append("image", fileObj.file);
    //   }
    // });
    // Create the details object with the new structure

    const details = {
      name: name || "",
      contact: {
        // code: countryCodeData?.code ? `+${countryCodeData.code}` : "",
        code: countryCodeData?.id ? `${countryCodeData.id}` : "",
        number: mobiles || "",
      },

      email: emails || "",
      company_name: companyName || "",
      leads: {
        id: leadData.id,
        name: leadData.name,
      },
      comment: {
        comment: comment || "",
      },
      author: author,
      branches:
        selectedBranchOptions?.map((branch) => ({
          id: branch?.aid || "",
          name: branch?.name || "",
        })) || [],
      state: stateData?.aid ? { id: stateData.aid, name: stateData.name } : {},
      college: collegeData || "",
    };

    formData.append("details", JSON.stringify(details));

    // Append image files if available
    files.forEach((fileObj) => {
      if (fileObj.file) {
        formData.append("image", fileObj.file);
      }
    });

    // Append audio files
    if (selectedAudioNames.length > 0) {
      selectedAudioNames.forEach((audioFile, index) => {
        formData.append(`audio`, audioFile);
      });
    }

    try {
      await AxiosInstance.post("/lead_passing_form", formData, config)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Lead Passing Data Saved Successfully");
            setTimeout(() => {
              Navigate("/lead_passing_form_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const [currentAudio, setCurrentAudio] = useState(null);

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const handlePlayAudio = (audioElement) => {
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    setCurrentAudio(audioElement);
  };

  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#06113C",
    color: "#fff",
    border: "1px solid #06113C",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "80%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4%",
          background: "#efefef",
        }}
      >
        <Paper
          variant="outlined"
          style={{ border: "2px solid #9BA4B5", borderRadius: "10px" }}
        >
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#081f37",
                fontSize: {
                  xs: "20px",
                  sm: "25px",
                  md: "30px",
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "0.5px",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              Lead Passing Form
            </Box>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "1%",
            }}
          >
            <Grid container spacing={1} item={12}>
              <Grid md={3} xs={12}>
                <Item>
                  <TextField
                    name="name"
                    fullWidth
                    id="name"
                    label="Name"
                    value={name}
                    onChange={handleInputChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>
              <Grid md={3} xs={12}>
                <Item>
                  {/* <Autocomplete
                    options={
                      countryCodeData ? [countryCodeData] : countryCodeOption
                    }
                    value={countryCodeData}
                    name="countryCodeOption"
                    onChange={(event, newData_xy) => {
                      setCountryCodeData(newData_xy);
                    }}
                    onInputChange={(x, e) => {
                      if (e && e.length > 0) {
                        searchcountryCode(e);
                      } else {
                        setCountryCodeOption(defaultCountryCode);
                      }
                      setSearchCountryCode(e);
                    }}
                    // getOptionLabel={(option) => option.code}
                    getOptionLabel={(option) =>
                      `+${option.code} ${option.name}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Country Code"
                        variant="outlined"
                        value={searchCountryCode}
                        required={!emails}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  /> */}
                  <Autocomplete
                    options={countryCodeOption}
                    value={countryCodeData}
                    onChange={handleOptionSelect}
                    onInputChange={handleInputCountryCodeChange}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Country Code"
                        variant="outlined"
                        value={searchCountryCode}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />

                  {/* <Autocomplete
                    options={countryCodeOption}
                    value={countryCodeData}
                    onChange={handleOptionSelect}
                    onInputChange={handleInputCountryCodeChange}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Country Code"
                        variant="outlined"
                        value={searchCountryCode}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  /> */}
                </Item>
              </Grid>
              <Grid md={3} xs={12}>
                <Item>
                  <TextField
                    label="Mobile Number"
                    type="tel"
                    fullWidth
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 10, maxLength: 10 }}
                    name="mobiles"
                    value={mobiles}
                    required={!emails}
                    onChange={(e) => setmobiles(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>

              <Grid md={3} xs={12}>
                <Item>
                  <TextField
                    color="warning"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                    fullWidth
                    label="Email"
                    name="emails"
                    type="email"
                    autoComplete="user-email"
                    value={emails}
                    required={!mobiles}
                    onChange={handleEmailChange}
                  />
                </Item>
                <span
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    color: "red",
                    fontWeight: "bold",
                    marginTop: "-1%",
                  }}
                >
                  "Non Mandatory Field"
                </span>
              </Grid>

              {/* {isEmailValid && (
                <>
                  {files.map((fileObj) => (
                    <Grid md={3} xs={12} key={fileObj.id}>
                      <Item>
                        <TextField
                          type="file"
                          fullWidth
                          inputProps={{
                            accept: ".png",
                          }}
                          onChange={(e) => handleFileChange(fileObj.id, e)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      </Item>
                      {files.length > 1 && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeFileField(fileObj.id)}
                          style={{
                            color: "red",
                            marginTop: "-2%",
                            width: "25px",
                            height: "25px",
                            padding: "5px",
                            borderRadius: "50%",
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label="add"
                        onClick={addFileField}
                        style={{
                          color: "green",
                          marginTop: "-2%",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          padding: "5px",
                        }}
                      >
                        <AddIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  ))}
                </>
              )} */}
              <Grid md={12} xs={12} style={{ marginTop: "1%" }}>
                <h1
                  style={{
                    alignItems: "flex-start",
                    // borderBottom: "1px solid gray",
                    borderTop: "1px solid gray",
                  }}
                >
                  <p style={{ fontSize: "18px", marginTop: "1%" }}>
                    Select Lead
                  </p>
                </h1>
              </Grid>

              {load ? (
                <div>Loading...</div>
              ) : leadOption && leadOption.length > 0 ? (
                leadOption.map((lead) => (
                  <Grid
                    key={lead.id}
                    md={2.4}
                    xs={12}
                    style={{ alignItems: "flex-start", marginTop: "1%" }}
                  >
                    <Tooltip
                      title={
                        <span style={{ fontSize: "12px" }}>
                          {leadDescriptionMap[lead.id] ||
                            "Description not available"}
                        </span>
                      }
                      arrow
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={leadData.id === lead.id}
                            onChange={() =>
                              handleLeadCheckboxChange(lead.id, lead.name)
                            }
                          />
                        }
                        label={lead.name}
                      />
                    </Tooltip>
                  </Grid>
                ))
              ) : (
                <div>Lead data Not found</div>
              )}

              {leadData.id == 1 && (
                <Grid md={3} xs={12} style={{ marginTop: "0.5%" }}>
                  <Item>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      value={selectedBranchOptions}
                      onChange={handleOptionSelect1}
                      onInputChange={handleInputChange1}
                      options={branchOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={option.name + (index + 1)}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Branch"
                          // error={!isBranchValid}
                          // helperText={
                          //   !isBranchValid
                          //     ? "Branch is required when lead as 'Enquiry Course'"
                          //     : ""
                          // }
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                  <span
                    style={{
                      display: "flex",
                      margin: "0 auto",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "bold",
                      marginTop: "-1%",
                    }}
                  >
                    "Non Mandatory Field"
                  </span>
                </Grid>
              )}
              {leadData.id == 2 && (
                <Grid md={3}>
                  <Item>
                    <TextField
                      name="companyName"
                      fullWidth
                      id="companyName"
                      label="Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                  <span
                    style={{
                      display: "flex",
                      margin: "0 auto",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "12px",
                      color: "red",
                      fontWeight: "bold",
                      marginTop: "-1%",
                    }}
                  >
                    "Non Mandatory Field"
                  </span>
                </Grid>
              )}
              {(leadData.id == 3 || leadData.id == 5) && (
                <>
                  <Grid item md={3}>
                    <Item>
                      <Autocomplete
                        options={stateOption}
                        value={stateData}
                        name="stateOption"
                        onChange={(event, newData) => {
                          setStateData(newData);
                        }}
                        onInputChange={(event, value) => {
                          if (value && value.length > 0) {
                            searchState(value);
                          } else {
                            setStateOption(defaultState);
                          }
                          setSearchTerm(value);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Item>
                  </Grid>
                  <Grid item md={3}>
                    <Item>
                      <TextField
                        name="collegeData"
                        fullWidth
                        rows={1}
                        id="collegeData"
                        label="College"
                        value={collegeData}
                        onChange={(e) => setCollegeData(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </Item>
                  </Grid>
                </>
              )}

              {/* <Grid md={3} xs={12}>
                <Item> */}
              {/* <Autocomplete
                    options={leadData ? [leadData] : leadOption}
                    value={leadData}
                    name="leadOption"
                    onChange={(event, newData_xy) => {
                      setLeadData(newData_xy);
                    }}
                    onInputChange={(x, e) => {
                      if (e && e.length > 0) {
                        searchlead(e);
                      } else {
                        setLeadOption(defaultLead);
                      }
                      setSearchLead(e);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Lead"
                        variant="outlined"
                        value={searchLead}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  /> */}

              {/* <Autocomplete
    options={leadData ? [leadData] : leadOption}
    value={leadData}
    name="leadOption"
    onChange={(event, newData_xy) => {
      setLeadData(newData_xy);
    }}
    onInputChange={(x, e) => {
      if (e && e.length > 0) {
        searchlead(e);
      } else {
        setLeadOption(defaultLead);
      }
      setSearchLead(e);
    }}
    getOptionLabel={(option) => option.name}
    renderOption={(props, option) => (
      <li {...props} key={option.id}>
        {option.name}
      </li>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Select Lead"
        variant="outlined"
        value={searchLead}
        required
        sx={{
          "& .MuiOutlinedInput-root": {
            borderColor: "rgba(0, 0, 0, 10)",
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 5)",
            },
          },
        }}
      />
    )}
  /> */}
              {/* </Item>
              </Grid> */}
              {/* <Grid md={3} xs={12}>
                <Item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Checkbox
                    name="selectBranch"
                    checked={selectBranch}
                    onChange={handleSelectBranch}
                    id="send_mail"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                  />{" "}
                  <label htmlFor="selectBranch" style={{ fontSize: "16px" }}>
                    Select Branch
                  </label>
                </Item>
              </Grid> */}
              {/* <Grid md={3} xs={12}>
                <Item>
                  <Autocomplete
                    options={branchData ? [branchData] : branchOption}
                    value={branchData}
                    name="branchOption"
                    // disabled={!selectBranch}
                    onChange={(event, newData_xy) => {
                      setBranchData(newData_xy);
                    }}
                    onInputChange={(x, e) => {
                      if (e && e.length > 0) {
                        searchbranch(e);
                      } else {
                        setBranchOption(defaultBranch);
                      }
                      setSearchBranch(e);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Branch"
                        variant="outlined"
                        value={searchBranch}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
                <span
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  "Non Mandatory Field"
                </span>
              </Grid> */}

              {/* {mobiles.length === 10 && (
                <TableContainer
                  component={Paper}
                  sx={{
                    width: "90%",
                    overflowY: "auto",
                    whiteSpace: "nowrap",
                    marginTop: "1%",
                    marginLeft: "5%",
                    border: "1px solid gray",
                    maxHeight: window.innerWidth > 1800 ? 450 : 350,
                  }}
                >
                  <Table
                    sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            width: "100px !important",
                          }}
                        >
                          Sl No
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            width: "200px !important",
                          }}
                        >
                          Select
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            width: "300px !important",
                          }}
                        >
                          Audio
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            width: "200px !important",
                          }}
                        >
                          Call Date
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {load ? (
                        <StyledTableRow>
                          <TableCell colSpan={4} align="center">
                            Loading...
                          </TableCell>
                        </StyledTableRow>
                      ) : callRecord_list ? (
                        callRecord_list.map((value, index) => (
                          <TableRow key={index + "default criteria"}>
                            <StyledTableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              <Checkbox
                                style={{ padding: "3px 10px 3px 20px" }}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    value.audio,
                                    e.target.checked
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {value.audio ? (
                                <audio
                                  src={`https://testincub2.qspiders.com/api/v1/get_audio?name=${value.audio}`}
                                  controls
                                  controlsList="nodownload"
                                  onPlay={(e) => handlePlayAudio(e.target)}
                                />
                              ) : (
                                "NA"
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {value.call_date === null ||
                              value.call_date === ""
                                ? "NA"
                                : moment(toIsoFormat(value?.["call_date"]))
                                    .local()
                                    .format("DD-MM-yyyy hh:mm A")}
                            </StyledTableCell>
                          </TableRow>
                        ))
                      ) : (
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={4}
                            align="center"
                            style={{ color: "red", fontWeight: "bold" }}
                          >
                            No Data Found
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}

              <Grid md={12} xs={12} style={{ marginTop: "0.5%" }}>
                <Item>
                  <TextField
                    name="comment"
                    fullWidth
                    rows={1}
                    multiline
                    id="comment"
                    label="Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>

              <Grid item container md={12} spacing={2}>
                <Grid item md={1.5}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={btnstyle1}
                    fullWidth
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={1.5}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Pass
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </article>
    </section>
  );
}
